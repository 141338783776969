.card.intents__content, .cen_card.intents__content {
  gap: 0;
  padding-top: 0 !important;
  overflow: inherit;

  .intents__header {
    position: sticky;
    top: -20px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
    padding: 25px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    background: white;
    z-index: 1;

    .intents__title {
      display: flex;
      flex-direction: column;
      line-height: 1.2;
    }

    .intents__actions {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 15px;
      }
    }
  }

  .action_add, .action_delete {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25px;
    cursor: pointer;
    background: transparent;
    border: inherit;
    margin-top: 12px;

    &:disabled {
      opacity: 0.6;
      cursor: default;
      pointer-events: none;
    }
  }

  .action_add {
    color: #565671;
    font-size: 14px;
  }

  .action_delete {
    color: #FF2F56;
    font-size: 12px;
  }

  .intents__elements {
    display: grid;

    &_column {
      display: grid;
      grid-template-columns: 65% auto;
      max-width: 1500px;
      gap: 15px;
      width: 100%;
      margin: 0 auto;

      > div {
        display: grid;
        gap: 15px;
      }

      &_divider {
        display: none;
      }
    }

    .intents__element {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      max-width: 1500px;
      width: 100%;
      margin: 0 auto;

      &.grid {
        display: grid;
        justify-content: inherit;
        row-gap: 10px;

        .intents__element_header {
          max-width: 100%;
        }

        .elements__accordion {
          max-width: 100%;

          .accordion__title {
            display: grid;

            span {
              &:nth-child(1) {
                font-size: 16px;
              }

              &:nth-child(2) {
                font-size: 10px;
              }
            }

          }

          .accordion__actions {
            display: flex;
            justify-content: end;
            column-gap: 20px;
            align-items: center;
            width: 100%;

            .MuiFormControlLabel-labelPlacementStart {
              margin: 0;
            }

            span {
              font-size: 12px;
            }

            svg {
              width: 18px;
              height: 18px;
            }
          }

          .MuiAccordionSummary-expandIcon {
            .MuiIconButton-label svg {
              width: 15px;
              height: 15px;

              path {
                stroke: #f4f4f4;
              }
            }
          }

          .MuiAccordion-root::before {
            content: inherit;
          }

          .accordion__content {
            display: grid;
            row-gap: 15px;
            width: 100%;

            .input-select {
              max-width: 550px;
            }

            .accordion__element {
              display: grid;
              gap: 10px;

              .platform_select_simple {
                max-width: 550px;
              }

              .accordion__element_header {
                display: flex;
                line-height: 1.2;
                justify-content: space-between;
                align-items: center;

                button {
                  font-size: 12px;
                  height: 35px;
                }

                span {
                  &:nth-child(1) {
                    font-size: 16px;
                  }

                  &:nth-child(2) {
                    font-size: 11px;
                    opacity: 0.6;
                    color: #000;
                  }
                }
              }

              .accordion__element_content {
                .MuiAlert-root {
                  margin-top: 0;
                }

                &.phrases {
                  display: grid;
                  row-gap: 10px;

                  .action_add {
                    border: 1px dashed #565671;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    border-radius: 15px 15px 0 15px;
                    height: 50px;
                  }

                  .accordion__elements {
                    display: flex;
                    column-gap: 8px;
                    align-items: center;

                    &.align-right {
                      justify-content: flex-end;

                      * {
                        width: auto;
                      }
                    }

                    &_conditionals {
                      display: grid;
                      grid-template-columns: 300px repeat(auto-fit, minmax(200px, 1fr));
                      gap: 10px;
                      width: 100%;

                      .Mui-disabled {
                        background: #e7e7e7;
                        border-radius: 5px;

                        svg {
                          display: none;
                        }
                      }

                      .platform_select_simple {
                        max-width: 100%;
                      }
                    }

                    &_events {
                      width: 100%;
                      display: flex;
                      gap: 10px;

                      > .platform_select_simple {
                        max-width: 300px;
                      }

                      textarea {
                        font-size: 13px;
                      }

                      &_response {
                        display: flex;
                        width: 100%;
                        gap: 10px;
                      }
                    }
                  }

                  .focus {
                    transition: all 0.40s linear !important;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                  }
                }

                .input-secondary {
                  border: inherit !important;
                }

                textarea, input {
                  font-size: 13px;
                }
              }
            }

            .accordion__element_param_content {
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
              column-gap: 30px;
              row-gap: 30px;
              align-items: flex-start;

              .accordion__element_param {
                transition: all 0.5s linear;
                border-radius: 5px;

                .MuiInput-input {
                  font-size: 12px;
                }
              }

              .accordion__element_param {
                background: white;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                .accordion__element_header {
                  display: grid;
                  grid-template-columns: auto 25px;
                  column-gap: 10px;
                  height: 55px;
                  background: #B2E6C8;
                  color: #565671;
                  border-radius: 5px 5px 0 0;
                  padding: 5px 20px;

                  .platform_input {
                    min-height: 25px;
                    padding: 0 !important;
                  }

                  > div {
                    &:nth-child(1) {
                      display: grid;
                      line-height: 18px;

                      input {
                        font-size: 14px;
                        padding: 0;
                        color: #565671;

                        &::placeholder {
                          color: #565671 !important;
                          opacity: 0.6;
                        }
                      }

                      span {
                        font-size: 11px;
                      }
                    }

                    &:nth-child(2) {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-top: -5px;
                      cursor: pointer;
                    }
                  }
                }

                .accordion__element_content {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;
                  padding: 10px 20px;
                  position: relative;

                  .platform_select_simple .MuiSelect-selectMenu, .platform_select_simple .MuiAutocomplete-inputRoot {
                    height: 35px;
                    font-size: 12px;
                    min-height: 35px;
                  }

                  .platform_input {
                    min-height: 35px;
                  }

                  .accordion__params_checkbox {
                    display: grid;
                    grid-template-columns: auto 70px;
                    column-gap: 15px;

                    &.identification {
                      grid-template-columns: auto 140px 70px;
                    }

                    .checkbox {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      line-height: 0;
                      height: 35px;

                      .Mui-checked {
                        color: #565671;
                      }

                      > span {
                        &:nth-child(1) {
                          color: rgba(0, 0, 0, 0.4);
                          font-size: 9px;
                        }
                      }
                    }
                  }

                  .accordion__params_prompts {
                    display: grid;
                    grid-template-columns: auto 37px;
                    column-gap: 10px;
                    align-items: center;

                    .float_toggle {
                      transform: scale(0.9, 0.9) translate3d(0, 0, 0);
                    }

                    .input-primary {
                      padding: 10px 15px !important;
                    }

                    textarea {
                      font-size: 12px;

                      &::placeholder {
                        color: #565671;
                        opacity: 0.7;
                      }
                    }
                  }

                  .accordion__element_use_custom_fields {
                    display: grid;
                    gap: 10px;

                    .platform_btn__dashed {
                      font-size: 12px;
                      height: 35px;
                    }

                    .action_add {
                      justify-content: flex-end;
                      font-size: 12px;
                      margin: 0;
                    }

                    .accordion__element_parameter_equivalence {
                      display: flex;
                      gap: 10px;

                      .input-primary {
                        padding: 10px 15px !important;
                        max-width: 160px;
                      }

                      input {
                        font-size: 12px;
                      }

                      .delete_equivalence {
                        min-width: 35px;
                        height: 35px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #F4F4F4;
                        border-radius: 5px;
                        cursor: pointer;

                        svg {
                          width: 12px;
                          height: 12px;
                        }
                      }
                    }
                  }

                  .actions {
                    display: flex;
                    gap: 5px;

                    .platform_btn.btn_icon {
                      height: 35px;
                      background: #f4f4f4;
                      min-width: 35px;

                      svg {
                        width: 12px;
                        height: 12px;
                      }
                    }
                  }
                }
              }
            }

            .accordion__element_grid_row {
              display: grid;
              row-gap: 10px;
            }

            .accordion__element_extension_content {
              display: grid;
              row-gap: 10px;

              input, textarea {
                font-size: 12px;
              }

              .accordion__element_jwt_options {
                display: grid;
                grid-template-columns: auto auto auto;
                column-gap: 15px;

                .jwt_access_key {

                  position: relative;

                  .platform_input__secondary:before {
                    content: "$.";
                    position: absolute;
                    left: 0;
                    height: 100%;
                    width: 25px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #B2E6C8;
                    color: #565671;
                    border-radius: 5px 0 0 5px;
                    font-size: 14px;
                    font-weight: 500;
                  }

                  #jwt_access_key {
                    padding-left: 20px !important;
                  }

                }

              }

              .accordion__element_type {
                display: grid;
                grid-template-columns: 110px auto;
                column-gap: 15px;
              }

              .MuiSelect-root, input.input-secondary {
                border: inherit !important;
                font-size: 12px !important;
              }

              textarea {
                border: inherit;
                font-size: 12px;
                color: #565671;

                &::placeholder {
                  font-weight: initial;
                }
              }

              .input-secondary {
                border: inherit !important;
              }

              .monospace {
                textarea {
                  font-family: monospace, sans-serif !important;
                }
              }

              .accordion__element_auth {
                display: grid;
                grid-template-columns: 250px auto;
                column-gap: 15px;
                row-gap: 15px;
                align-items: center;

                .elements__alert {
                  .MuiAlert-root {
                    height: 45px;
                  }
                }

                .accordion__element_auth_basic {
                  display: grid;
                  grid-template-columns: auto auto;
                  column-gap: 15px;
                }

              }

              .accordion__element_prompts {
                display: grid;
                align-items: center;
                grid-template-columns: auto 37px;
                column-gap: 15px;

                .float_toggle {
                  transform: scale(0.9, 0.9) translate3d(0, 0, 0);
                }
              }

            }

            .accordion_element_extension_new {
              border: 1px dashed #565671;
              filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              min-height: 187px;
              border-radius: 15px 15px 0 15px;
              flex-direction: column;
              line-height: 30px;
              transition: opacity 1s;

              &.disabled {
                opacity: 0.6;
                cursor: default;
                pointer-events: none;
              }

              span {
                &:nth-child(1) {
                  font-size: 18px;
                }

                &:nth-child(2) {
                  color: #FF2F56;
                  font-size: 12px;
                }
              }
            }
          }
        }

        .elements__chips {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;

          div.MuiChip-root {
            height: 40px;
            border: 1px solid #C1DAEE;
            background: white;
            border-radius: 5px;

            .MuiChip-label > span {
              display: flex;
              align-items: center;
              font-size: 14px;
              color: #565671;
            }

            /* Remove input type number arrows */
            /* Chrome, Safari, Edge, Opera */
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            /* Firefox */
            input[type="number"] {
              -moz-appearance: textfield;
            }

            .lifespan {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0 12px;
              height: 40px;
              margin: 0 0 0 10px;
              background: #C1DAEE;

              input[type="number"] {
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 5px;
                width: auto;
                max-width: 45px;
                height: 30px;
                border: none;
                text-align: center;
                margin-left: 5px;
                -moz-appearance: textfield;
              }
            }

            svg {
              color: #C1DAEE;
            }
          }

        }

        .elements__switch {
          display: grid;
          grid-template-columns: auto 58px;
          column-gap: 15px;
          align-items: center;
        }
      }

      .input-primary {
        .MuiInputBase-input {
          padding: 0;
          font-weight: initial;
        }
      }

      input, textarea {
        &::placeholder {
          opacity: 1;
          color: #BCBCC6;
          font-weight: initial;
        }
      }

      .intents__element_header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-self: center;
        line-height: 1.2;
        margin: 0;
        margin-right: 15px;
        width: 100%;
        max-width: 200px;

        span {
          &:nth-child(1) {
            height: 100%;
            font-size: 18px;
          }

          &:nth-child(2) {
            font-size: 13px;
            color: #000000;
            opacity: 0.6;
          }
        }
      }
    }
  }

  .MuiAlert-root {
    height: 40px;
    padding: 0 10px;
    align-items: center;
    margin-top: 0;
  }
}

/* INTEGRATIONS */
.elements__integrations {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));

  &--no-grid {
    grid-template-columns: none;
  }

  .element__integration {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 40px;
    border-radius: 5px;
    background-color: #F4F4F4;
    cursor: pointer;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.25);
    overflow: hidden;

    &_content {
      display: flex;
      gap: 10px;
      align-items: center;
      height: 100%;

      &_img {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFFFFF;
        height: 100%;
        width: 40px;

        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.info__integration {

  .element__integration_selector {
    width: 100%;
  }

  &_content {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));

    div > label {
      width: 100%;
      text-align: left;
      transform: translate(0, 1.5px) scale(0.75);
      color: #565671;
    }

    > .MuiFormControlLabel-labelPlacementStart {
      justify-content: left;
      margin: 0;

      span {
        font-size: 12px;
      }
    }

    &_skeleton {
      width: 100%;
      display: grid;
      gap: 10px;

      div {
        width: 100%;
        display: grid;
        gap: 5px;
      }

      span {
        border-radius: 5px;
      }
    }
  }
}

@media (max-width: 1250px) {
  .intents__content {
    .intents__elements_column {
      grid-template-columns: inherit;

      &_divider {
        display: block;
      }
    }
  }
}
@media (max-width: 600px) {
  .cen_card.intents__content .intents__elements_column {
    display: contents;
  }
  .cen_card.intents__content .intents__elements .intents__element {
    display: contents;
    padding: 0 5px 0 5px;
  }
}