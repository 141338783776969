.chat-view-content {
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: inherit;
  background-position: center;
  background-color: white;
}

@media (max-width:1250px) {
  .chat-view-content {
    background-size: contain;
  }
}