#app_bar, .app_bar {
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 35px;

    h2 {
      font-size: 21px;
    }

    .actions {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 10px;

      button {
        margin-top: 0;
      }
    }
  }

  .open_window {
    span.MuiTab-wrapper {
      display: grid;
      grid-template-columns: auto auto;
      column-gap: 10px;
      grid-auto-flow: row;
      direction: rtl;
    }

    svg {
      width: 12px;
    }
  }
}

.cen_card {
  width: 100%;
  background: white;
  border-radius: 5px;
  margin-bottom: 20px;

  &.sticky {
    margin-bottom: 0;
    overflow: inherit;
    position: sticky;
    top: -20px;
    background: white;
    z-index: 1;
  }

  .header {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #F4F4F4;

    &_content {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &_title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      line-height: 1.2;
      height: 40px;

      .title {
        color: #000000;
      }

      .description {
        color: rgba(0, 0, 0, 0.5);
        font-size: 13px;
      }
    }

    &_action {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 20px;
      cursor: pointer;
    }

    &_actions {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 12px;

      .test_chat {
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
        font-size: 12px;
        color: rgba(0,0,0,.54);
        font-weight: 500;
        cursor: pointer;

        svg {
          width: 12px;

          path {
            fill: rgba(0,0,0,.54);
          }
        }
      }
    }

  }

  .column_content{
    display: contents;
  }
  .scroll_table {
    overflow: auto !important;
  }
  .content {
    display: grid;
    gap: 12px;
    padding: 15px 20px 30px 20px;
    overflow: auto;

    .loading {
      height: 200px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: white;
      border-radius: 5px;
    }

    .elements__main {

      .MuiSwitch-root {
        opacity: 1;
      }

      .elements__artifacts {
        display: flex;
        column-gap: 15px;
      }

      &.switch__header {
        display: grid;
        grid-template-columns: auto 400px;

        .element__title {
          display: grid;
          justify-content: left;
          padding: 0;
          line-height: 1.2;

          p {
            margin: 0;
            margin-bottom: 10px;
          }
        }

        .element__actions {
          padding: 0;
          justify-content: flex-end;
          flex-direction: inherit;

          button {
            margin-left: 5px;
          }
        }
      }

      &.elements__webhook {

        > div {
          padding: 10px 50px;

          > div {
            display: grid;
            grid-template-columns: 150px auto;
            justify-content: initial;

            > .input-primary {
              width: calc(100% - 30px);
            }

            &.elements__grid {
              align-items: start;
              gap: 0;
            }
          }
        }

        .input-primary {
          min-height: 40px;
          padding: 7px 10px !important;
        }

        .elements__copy {
          display: grid;
          grid-template-columns: auto 40px;
          gap: 6px;
          align-items: center;

          svg {
            cursor: pointer;
          }
        }
      }

      input, textarea {
        width: 100%;
        margin: 0;
      }

      .elements__picker {
        input {
          padding: 2px;
        }

        .Mui-error.MuiFormHelperText-root {
          display: none;
        }

        .input-primary {
          max-width: 350px;
          height: 40px;
          justify-content: center;
          padding: 0 10px;
        }
      }

      .elements__grid {
        display: grid;
        gap: 15px;
        grid-template-columns: 100%;
      }

      .elements__grid_color {
        display: grid;
        grid-template-columns: auto 250px;
      }

      .elements__flex_column {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .MuiDivider-root {
          width: 100%;
        }
      }

      .elements__switch {
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        align-items: center;
      }

      .elements__accordion {
        max-width: 100%;
        overflow: visible;

        .accordion__content {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        .MuiAccordion-root {
          margin: 0 !important;
          border-radius: 5px;
          overflow: visible;

          .MuiAccordionSummary-content {
            margin: 0;
          }

          .MuiAccordionSummary-expandIcon {
            height: 40px;
            padding: 0 10px;
          }
        }

        .MuiIconButton-label {
          svg {
            width: 15px;
            height: 15px;

            path {
              stroke: #f4f4f4;
            }
          }
        }
      }

      .elements_codes {
        max-width: 100%;
        display: flex;

        .code-language, .intents-entities {
          background: #F4F4F4;
          padding: 0 15px;
          border-radius: 5px;
          max-width: 450px;

          .MuiInput-input {
            display: flex;
            align-items: center;
          }

          .input-select svg {
            right: 20px;
          }

        }
      }

      .elements__customize {
        display: grid;
        align-items: center;
        grid-template-columns: 360px auto;
        gap: 30px;
        text-align: left;

        .elements__icon {
          display: grid;
          grid-template-columns: 120px auto;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px 15px 0px 15px;
          max-width: 360px;
          min-height: 160px;

          .element__icon_img {
            display: flex;
            justify-content: center;
            align-items: center;

            img, svg {
              width: 90px;
              height: 90px;
            }
          }

          .element__icon_content {
            position: relative;
            background: #F4F4F4;
            padding: 15px 12px;
            border-radius: 0 15px 0 0;

            .element__icon_header {
              display: grid;
              grid-template-columns: auto 50px;
              margin-bottom: 12px;

              > div {
                display: grid;
                line-height: 18px;

                span {
                  &:nth-child(1) {
                    font-size: 16px;
                  }

                  &:nth-child(2) {
                    font-size: 11px;
                  }
                }
              }
            }

            .element__icon_info {
              font-size: 11px;
            }

            .element__icon_switch {
              position: absolute;
              bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 11px;
            }

            .element__icon_selector {
              position: absolute;
              display: flex;
              gap: 7px;
              padding: 4px 0;
              justify-content: center;
              align-content: center;
              background: white;
              bottom: 0;
              left: 0;
              right: 0;

              > span {
                padding: 6px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                transition: all 0.5s linear;
                height: 40px;
                cursor: pointer;

                &.active {
                  background: #F4F4F4;
                  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                }
              }

              svg {
                width: 30px;
                height: 22px;
              }
            }
          }
        }

        .elements__content {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 10px;

          .input-primary {
            padding: 0 !important;

            input {
              padding: 13px 10px;
            }
          }

          > div {
            &:nth-child(1), &:nth-child(2) {
              display: grid;
              gap: 10px;
            }

          }

          &.open_chat {
            > div {
              &:nth-child(1) {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                > div:nth-child(2) {
                  display: flex;
                  font-size: 12px;
                  width: 50%;
                  min-width: 360px;
                  align-items: center;
                }
              }

              &:nth-child(2) {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
              }
            }
          }

          &.header_chat {
            > div {
              &:nth-child(2) {
                grid-template-columns: repeat(auto-fill, 140px);
              }
            }

            .input-primary {
              padding: 13px !important;
            }
          }

          .element__checkbox {
            > div {
              span {
                font-size: 12px;
                padding: 1px;
              }

              .Mui-checked {
                color: #565671;
              }
            }
          }

        }

        &.messages_chat {
          grid-template-columns: 100%;

          .elements__messages {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            > div {
              > div {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                gap: 15px;

                .elements__colors {
                  display: flex;
                  gap: 15px;
                }
              }
            }

          }
        }

        &.artifacts_chat {
          grid-template-columns: 100%;

          .elements__artifacts {
            display: grid;
            gap: 20px;

            .elements__artifact {
              > span {
                font-size: 16px;
              }

              > div {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
                padding: 10px;
              }
            }
          }
        }

      }

      .elements__customize_open_chat {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        align-items: flex-start;

        .element__checkbox {
          .Mui-checked {
            color: #565671;
          }
        }
      }
    }

    table {
      width: 100%;
      border-spacing: 3px;
      table-layout: fixed;

      thead, tbody {

        tr {
          height: 40px;
          text-align: left;

          th, td {
            padding: 0 10px 0 10px;
            overflow: hidden;
            background: #f4f4f4;
          }

          th {
            color: #565671;
            font-weight: 600;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          td {
            color: #565671;
            font-weight: 400;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          td div.text-selector {
            flex-wrap: nowrap;
            padding: 0;
            width: 100% !important;
            margin: 0;
            font-size: 12px;
            overflow: auto;
            scrollbar-color: #C1DAEE transparent;
            gap: 5px;

            &::-webkit-scrollbar {
              height: 6px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #C1DAEE;
              box-shadow: inset 0 0 10px 10px red;
              border: solid 3px transparent;
            }

            ::-webkit-scrollbar-track {
              box-shadow: inset 0 0 10px 10px green;
              border: solid 3px transparent;
            }

            > div {
              height: 35px;
              padding: 0 6px;
              align-items: center;
              margin: 0;

              > div {
                background: white;
                font-size: 14px;
                border-radius: 50%;
                display: flex;
                align-content: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                margin-left: 6px;

                > span {
                  line-height: 25px;
                }
              }
            }
          }

          td.default-fallback {
            text-align: center;
          }

          td.svg {
            cursor: pointer;
            transition: transform 1s;

            .action-buttons {
              display: grid;
              grid-template-columns: auto auto;
              column-gap: 15px;
              justify-content: center;
            }

            button {
              padding: 0;

              &:disabled {
                svg {
                  visibility: hidden;
                }
              }

            }

            svg {
              &:hover {
                transform: scale(0.9);
                transition: transform 1s;

                path {
                  stroke: #83BCEB;
                }
              }
            }

            &.view svg {
              width: 25px;
              height: 16px;
            }

            &.edit svg, .edit svg, .delete svg   {
              width: 25px;
              height: 25px;
            }

            &.delete svg, .delete svg {
              &:hover {
                path {
                  stroke: #FF2F56;
                }
              }
            }
          }

          td.rounded {
            padding-left: 0;

            div:first-child {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                width: 1.5em;
                height: 1.5em;
              }
            }
          }

          td.rounded-deploy {
            padding-left: 0;

            > div {
              display: grid;
              grid-template-columns: 50% auto;
              column-gap: 10px;
              align-items: center;

              div:first-child {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  width: 1.5em;
                  height: 1.5em;
                  fill: #565671;
                }
              }

              > div:nth-child(2) {
                display: flex;
                justify-content: center;
                cursor: pointer;

                svg {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }

          th:first-child, td:first-child {
            border-radius: 5px 0 0 5px;
          }

          td, th {
            font-size: 14px;
          }

          td:nth-child(1) {
            font-weight: 500;
          }

          th:last-child, td:last-child {
            border-radius: 0 5px 5px 0;
          }

          &.table-search {
            background: white;

            td {
              font-size: 20px;
            }
          }

        }
      }

      &[class^="table-"] {

        .table_noResults {
          border-radius: 50px;
          padding: 20px;
        }
      }

      &.table-contexts {
        td:last-child {
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: auto;
        }

        th:nth-child(2), td:nth-child(2) {
          width: auto;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 50px;
        }

      }

      &.table-actions {
        td:last-child {
          padding-left: 0;
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: auto;
        }

        th:nth-child(2), td:nth-child(2) {
          width: auto;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 250px;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 150px;
        }

        td:nth-child(2) {
          font-weight: bold;
        }

        .table_element__view {
          display: flex;
          gap: 10px;

          > div {
            cursor: pointer;
          }
        }

      }

      &.table-intents {
        tr {
          cursor: pointer;
        }

        td:last-child {
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: 250px;
        }

        th:nth-child(2), td:nth-child(2) {
          width: 250px;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 160px;
        }

        th:nth-child(5), td:nth-child(5), th:nth-child(6), td:nth-child(6), th:nth-child(7), td:nth-child(7), th:nth-child(8), td:nth-child(8), th:nth-child(9), td:nth-child(9) {
          width: 75px;
          text-align: center;

          > div {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              cursor: inherit;
            }

            img {
              width: 25px;
            }
          }
        }

        th:nth-child(10), td:nth-child(10) {
          width: 60px;
        }

        th:nth-child(1), th:nth-child(4) {
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
              height: 25px;
              width: 25px;
              cursor: pointer;
            }
          }
        }

        th {
          svg path {
            fill: #C4C4C4;
            transition: fill 1s;
          }
        }

        th.active {
          svg path {
            fill: #565671;
          }
        }

        th svg:hover path {
          fill: #565671;
        }
      }

      &.table-entities {
        td:last-child {
          padding-left: 0;
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: 300px;
        }

        th:nth-child(2), td:nth-child(2) {
          width: 50%;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 50%;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 60px;
        }

        th:nth-child(1), th:nth-child(3) {

          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;

            svg {
              height: 25px;
              width: 25px;
              cursor: pointer;
            }
          }
        }

        th {
          svg path {
            fill: #C4C4C4;
            transition: fill 1s;
          }
        }

        th.active {
          svg path {
            fill: #565671;
          }
        }

        th svg:hover path {
          fill: #565671;
        }
      }
    }
  }
}

.card {
  &.project_create {
    border-radius: 5px;
    max-width: 1300px;
    margin: 0 auto;
  }

  .content {
    .elements_title {
      display: flex;
      flex-direction: column;
      line-height: 1.2;

      .elements__main {
        display: grid;
        margin-top: 0;
        line-height: 1.2;
        width: 100%;
      }
    }

    .elements__main {
      .elements__artifacts {
        display: flex;
        column-gap: 15px;
      }

      &.switch__header {
        display: grid;
        grid-template-columns: auto 400px;

        .element__title {
          display: grid;
          justify-content: left;
          padding: 0;
          line-height: 1.2;

          p {
            margin: 0;
            margin-bottom: 10px;
          }
        }

        .element__actions {
          padding: 0;
          justify-content: flex-end;
          flex-direction: inherit;

          button {
            margin-left: 5px;
          }
        }
      }

      &.elements__webhook {

        > div {
          padding: 10px 50px;

          > div {
            display: grid;
            grid-template-columns: 150px auto;
            justify-content: initial;

            > .input-primary {
              width: calc(100% - 30px);
            }

            &.elements__grid {
              align-items: start;
              gap: 0;
            }
          }
        }

        .input-primary {
          min-height: 40px;
          padding: 7px 10px !important;
        }

        .elements__copy {
          display: grid;
          grid-template-columns: auto 40px;
          gap: 6px;
          align-items: center;

          svg {
            cursor: pointer;
          }
        }
      }

      input, textarea {
        width: 100%;
        margin: 0;
      }


      .elements__picker {
        input {
          padding: 2px;
        }

        .Mui-error.MuiFormHelperText-root {
          display: none;
        }

        .input-primary {
          max-width: 350px;
          height: 40px;
          justify-content: center;
          padding: 0 10px;
        }
      }

      .elements__grid {
        display: grid;
        gap: 20px;
        grid-template-columns: 100%;
      }

      .elements__grid_color {
        display: grid;
        grid-template-columns: auto 250px;
      }

      .elements__flex_column {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .MuiDivider-root {
          width: 100%;
        }
      }

      .elements__switch {
        display: flex;
        justify-content: space-between;
        max-width: 100%;
        align-items: center;
      }

      .elements__accordion {
        max-width: 100%;

        .MuiIconButton-label {
          svg {
            width: 15px;
            height: 15px;

            path {
              stroke: #f4f4f4;
            }
          }
        }
      }

      .elements_codes {
        max-width: 100%;
        display: flex;
        margin-bottom: 10px;

        .code-language, .intents-entities {
          background: #F4F4F4;
          padding: 0 15px;
          border-radius: 5px;
          max-width: 450px;

          .MuiInput-input {
            display: flex;
            align-items: center;
          }

          .input-select svg {
            right: 20px;
          }

        }
      }

      .elements__customize {
        display: grid;
        align-items: center;
        grid-template-columns: 360px auto;
        gap: 30px;
        text-align: left;

        .elements__icon {
          display: grid;
          grid-template-columns: 120px auto;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 15px 15px 0px 15px;
          max-width: 360px;
          min-height: 160px;

          .element__icon_img {
            display: flex;
            justify-content: center;
            align-items: center;

            img, svg {
              width: 90px;
              height: 90px;
            }
          }

          .element__icon_content {
            position: relative;
            background: #F4F4F4;
            padding: 15px 12px;
            border-radius: 0 15px 0 0;

            .element__icon_header {
              display: grid;
              grid-template-columns: auto 50px;
              margin-bottom: 12px;

              > div {
                display: grid;
                line-height: 18px;

                span {
                  &:nth-child(1) {
                    font-size: 16px;
                  }

                  &:nth-child(2) {
                    font-size: 11px;
                  }
                }
              }
            }

            .element__icon_info {
              font-size: 11px;
            }

            .element__icon_switch {
              position: absolute;
              bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-size: 11px;
            }

            .element__icon_selector {
              position: absolute;
              display: flex;
              gap: 7px;
              padding: 4px 0;
              justify-content: center;
              align-content: center;
              background: white;
              bottom: 0;
              left: 0;
              right: 0;

              > span {
                padding: 6px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 40px;
                transition: all 0.5s linear;
                height: 40px;
                cursor: pointer;

                &.active {
                  background: #F4F4F4;
                  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                }
              }

              svg {
                width: 30px;
                height: 22px;
              }
            }
          }
        }

        .elements__content {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 10px;

          .input-primary {
            padding: 0 !important;

            input {
              padding: 13px 10px;
            }
          }

          > div {
            &:nth-child(1), &:nth-child(2) {
              display: grid;
              gap: 10px;
            }

          }

          &.open_chat {
            > div {
              &:nth-child(1) {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

                > div:nth-child(2) {
                  display: flex;
                  font-size: 12px;
                  width: 50%;
                  min-width: 360px;
                  align-items: center;
                }
              }

              &:nth-child(2) {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
              }
            }
          }

          &.header_chat {
            > div {
              &:nth-child(2) {
                grid-template-columns: repeat(auto-fill, 140px);
              }
            }

            .input-primary {
              padding: 13px !important;
            }
          }

          .element__checkbox {
            > div {
              span {
                font-size: 12px;
                padding: 1px;
              }

              .Mui-checked {
                color: #565671;
              }
            }
          }

        }

        &.messages_chat {
          grid-template-columns: 100%;

          .elements__messages {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            > div {
              > div {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                gap: 15px;

                .elements__colors {
                  display: flex;
                  gap: 15px;
                }
              }
            }

          }
        }

        &.artifacts_chat {
          grid-template-columns: 100%;

          .elements__artifacts {
            display: grid;
            gap: 20px;

            .elements__artifact {
              > span {
                font-size: 16px;
              }

              > div {
                display: flex;
                flex-wrap: wrap;
                gap: 15px;
                padding: 10px;
              }
            }
          }
        }

      }

      .elements__customize_open_chat {
        display: grid;
        grid-template-columns: auto auto;
        gap: 10px;
        align-items: flex-start;

        .element__checkbox {
          .Mui-checked {
            color: #565671;
          }
        }
      }
    }

    .elements__upgrade {
      > p {
        margin-top: 0;
      }

      > div {
        padding: 20px 50px;
      }
    }

    .elements__info {
      display: flex;
      flex-direction: column;
      align-items: end;

      > span {
        font-size: 12px;
      }
    }
  }

  .element__color {
    display: flex;
    flex-direction: column;

    &.start {
      align-items: flex-start;
    }

    &.end {
      align-items: flex-end;
    }

    button, button span > div {
      width: 40px;
      min-width: 40px;
      height: 40px;
      border-radius: 50%;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    > div {
      height: 40px;
      border-radius: 20px;
      background: #f4f4f4;
      display: flex;
      gap: 10px;
      align-items: center;
      width: 140px;

      > div {
        align-items: center;
        gap: 15px;
      }

      .MuiTextField-root {
        pointer-events: none;
      }

      input {
        height: 40px;
        color: #565671;
      }
    }
  }
}

.elements__inactivity {
  display: grid;
  grid-template-columns: 40px auto 40px 40px;
  justify-content: initial;
  column-gap: 12px;
  row-gap: 12px;

  .float_buttons {
    height: 42px;
  }

  .elements__lang, .elements__close {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f4f4f4;
    height: 40px;
    width: 40px;
    border-radius: 5px;

  }

  .elements__lang {
    text-transform: uppercase;
  }

  .elements__close {
    cursor: pointer;

    svg {
      width: 18px;
    }
  }
}

.btn-primary {
  &.export, &.import, &.sandbox {
    svg path {
      transition: stroke 1s;
    }

    &:hover {
      svg {
        path {
          stroke: #565671;
        }
      }
    }
  }

  &.import {
    max-width: 350px;
    border-radius: 5px;
    height: 40px;
    font-size: 13px;

    svg {
      width: 18px;
    }

    p {
      align-items: center;
      display: flex;
      gap: 10px;
      margin: 0;
    }
  }

  &.sandbox_open_window, &.sandbox_edit {

    span {
      display: flex;
      align-items: center;
    }
  }

  &.sandbox_open_window {
    svg {
      width: 18px;

      path {
        stroke: white;
        fill: white;
        transition: all 1s;
      }
    }

    &:hover {
      svg {
        path {
          stroke: #565671;
          fill: #565671;
        }
      }
    }
  }
}

.muicc-colorbox-error {
  display: none;
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    font-size: 12px;
  }
}

.MuiTooltip-tooltip {
  div.btn_tooltip_info {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: 2px;

    span {
      background-color: rgba(97, 97, 97, 0.9);
      padding: 5px;
      border-radius: 4px;
      width: max-content;

      &:nth-child(2) {
        font-size: 9px;
        padding: 2px;
        border-radius: 3px;
      }
    }
  }
}


@media (max-width: 1750px) {
  .elements__customize {
    grid-template-columns: 100% !important;
    gap: 20px;
  }
}

@media (max-width: 1000px) {
  .cen_card .header {
    grid-template-columns: none;
    white-space: pre-wrap; 
    padding: 14px 5px;
    display: grid;
    grid-gap: 20px;

  }
  .cen_card .spacing_title{
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 735px) {
  .cen_card .header_actions {
    display: contents;
  }
}
@media (max-width: 600px) {
  .cen_card .content{
    padding: 15px 5px 30px 10px;
  }
  .cen_card .content .elements__main.elements__webhook > div > div{
    grid-template-columns: none;
  }
  .cen_card .content .elements__main.elements__webhook > div {
    padding: 0;
  }
}
@media (max-width: 1013px){
  .cen_card .header {
    grid-template-columns: none;
  }
  .cen_card .header_actions {
    justify-content: start;
  }
}
@media (max-width: 870px){
  .cen_card .header_actions {
    display: inherit;
    justify-content: space-between;
    margin-top: 7px;
  }
}