.stats-container {
  position: relative;
  margin-bottom: 50px;

  .stats-header {
    background: white;
    padding: 10px 15px;
    border-radius: 0 0 5px 5px;
    min-height: 60px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .stats-date-container {
      display: grid;
      grid-template-columns: auto auto;
      gap: 20px;

      .select-secondary {
        max-height: 40px;
        em {
          margin-right: 5px;
        }

        svg {
          right: 10px;
        }
      }

      .stats-date {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 20px;

        > div:first-child {
          display: grid;
          grid-template-columns: auto auto;
          column-gap: 20px;
        }

        .MuiFormControl-root {
          display: none;
        }
      }

      .platform_input_content {
        .platform_input {
          min-height: 40px;

          input {
            font-size: 12px;
          }
        }
      }
    }

    div.actions {
      display: flex;
      gap: 20px;

      button {
        margin-top: 0;
      }
    }

    .start-date, .end-date {
      width: auto;
      display: grid;
      height: 40px;
      background: white;
      z-index: 1;
      grid-template-columns: auto auto;
      column-gap: 20px;
      cursor: pointer;

      div {
        background: #F7F7F7;
        border-radius: 5px;
        height: 40px;
        justify-content: center;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 10px;

        svg {
          width: 20px;
          height: 20px;
        }

        span {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        span:nth-child(2) {
          min-width: 60px;
        }

        span:nth-child(3) {

          svg {
            width: 10px;
            height: 10px;

            path {
              fill: #565671;
            }
          }
        }
      }

    }
  }

  .stats-alert {
    margin-top: 10px;
  }

  .stats-card {
    padding: 15px 20px 30px 20px;
    margin-top: 10px;
    border-radius: 5px;
    background: white;
  }

  .stats-content.polls {
    margin-top: 20px;

    .stats-polls {
      > div {
        &:first-child {
          border-radius: 15px 15px 0 0;
        }

        &:last-child {
          border-radius: 0 0 0 15px;
        }
      }
    }
  }

  .stats-graph {
    display: grid;
    row-gap: 15px;
  }

}

.legend {
  width: 100%;
  display: grid;
  grid-template-columns: 180px auto;
  align-items: flex-start;
  column-gap: 20px;

  .legend_content {
    display: grid;
    row-gap: 12px;

    .legend_header {
      display: grid;
      row-gap: 5px;
      background: white;
      text-align: center;
      padding: 15px;
      border-radius: 5px;

      .legend_title {
        font-size: 14px;
        border-bottom: 1px solid #565671;
        overflow: hidden;
      }

      .legend_total {
        font-size: 36px;
        height: 36px;
        margin-top: -7px;
      }
    }

    .legend_elements {
      display: grid;
      background: white;
      border-radius: 5px;
      padding: 10px 15px;
      row-gap: 6px;
      max-height: 405px;
      overflow: hidden;

      .legend_element {
        display: grid;
        position: relative;
        grid-template-columns: 65px 88px;
        border-radius: 5px 5px 0 5px;
        cursor: pointer;

        &.simplify {
          .legend_right, .legend_left {
            padding-top: 3px;
            padding-bottom: 3px;
          }
        }


        .inactive {
          position: absolute;
          width: 153px;
          height: 1px;
          background: black;
          top: 50%;
        }

        .legend_right, .legend_left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        .legend_left {
          padding-left: 5px;
          border-radius: 5px 0 0 5px;
          line-height: 1.15;

          span:nth-child(1) {
            font-size: 12px;
          }

          span:nth-child(2) {
            font-size: 6px;
            text-transform: uppercase;
          }
        }

        .legend_right {
          background: white;
          border-width: 1px;
          border-style: solid;
          text-align: right;
          padding-right: 5px;
          border-radius: 0 5px 0 0;
          line-height: 1;

          span:nth-child(1) {
            font-size: 15px;
          }

          span:nth-child(2) {
            font-size: 6px;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .chartjs-render-monitor {
    background: white;
    padding: 40px;
    box-sizing: border-box;
    border-radius: 5px;
    height: 500px;
    width: 100% !important;
  }
}

.legend_empty {
  background: white;
  padding: 20px 70px;
  border-radius: 5px;
  display: grid;
  row-gap: 12px;

  span:nth-child(1) {
    font-size: 20px;
  }
}

#root .app_bar .MuiPaper-root, .stats-container .MuiAlert-root {
  filter: inherit;
  white-space: pre-wrap;
}

@media (max-width: 750px) {
  .stats-container .stats-header .stats-date-container .stats-date {
    display: contents;
  }
  .legend {
   display: block;
  }
  .legend .chartjs-render-monitor {
    width: auto !important;
    overflow: auto;
    padding: 0px;
  }
}

@media (max-width: 640px){
  .stats-container {
    width: fit-content;
  }
}