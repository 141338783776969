.image__element_short {
  width: 300px !important;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 20px;
    cursor: pointer;
  }

  .image__element_button {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #f4f4f4;
    margin: 0 !important;
    border-radius: 10px;
    box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    cursor: pointer;
    display: none;
  }

  &:hover {
    .image__element_button {
      display: flex;
    }
  }
}

.image__element_long {
  position: fixed;
  right: 0px;
  bottom: 0px;
  height: 100%;
  width: 100%;
  z-index: 1000;
  margin: 0!important;
  background: #FFFFFF;
  display: grid;
  top: 0;
  overflow: auto;

  .image__element_header {
    margin: 0 !important;
    height: 80px;
    background: #565671;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px;
    gap: 15px;

    span {
      color: white;
      font-size: 16px;
    }

    button {
      max-width: 150px !important;

      &:hover {
        color: #565671;
      }
    }
  }

  .image__element_image {
    max-height: 30vw;
    height: 100%;
    margin: 0 auto !important;

    img {
      box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
      border-radius: 10px;
      height: auto;
      max-height: 30vw;
    }
  }
}