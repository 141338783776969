.centribal_card {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  border-radius: 5px;
  overflow: hidden;
  max-height: 100%;

  > div > .MuiAlert-root {
    filter: inherit;
  }

  .campaigns {
    &_header {
      display: grid;
      grid-template-columns: auto auto;
      border-bottom: 1px solid #f4f4f4;
      padding: 10px 20px;
      gap: 10px;

      &_title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        line-height: 1.2;
      }

      &_actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 10px;
        overflow: auto;

        .platform_select_simple {
          min-width: 200px;
        }

        .platform_btn {
          &.disabled {
            pointer-events: auto;
            cursor: pointer;
            background-color: #F4F4F4;
            color: #565671;

            &:hover {
              background-color: #F4F4F4;
              color: #565671;
            }
          }
        }

        .platform_date_picker_groups {
          display: flex;
          flex-direction: column;
          align-items: center;

          > span {
            width: 100%;
            font-size: 10px;
          }

          > div {
            cursor: pointer;
            width: 130px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            grid-gap: 10px;
            background: #f4f4f4;
            border-radius: 5px;
          }
        }

        .date_picker_groups {
          display: none;
        }

        .groups_status, .templates_status {
          max-width: 200px;
        }

        .groups_whatsapp_phones, .templates_whatsapp_phones, .templates_category {
          max-width: 300px;
        }
      }
    }

    &_tabs {
      display: flex;
      height: 40px;
      border-bottom: 1px solid #f4f4f4;

      > div {
        padding: 10px 40px;
        border-right: 1px solid #f4f4f4;
        cursor: pointer;
        background: white;
        color: #565671;
        transition: all 0.2s linear;

        &.active {
          background: #565671;
          color: white;
        }
      }
    }

    &_container {
      &_elements {
        display: grid;
        grid-template-columns: auto 300px;
        height: 100%;
        overflow: hidden;

        .webhook {
          overflow: auto;

          .MuiAlert-root {
            border-radius: 0;
            filter: inherit;
          }
        }

        &_alone {
          grid-template-columns: 100%;
        }

        &_content {
          overflow: auto;
          height: 100%;
        }

        &_preview {
          height: 100%;
          display: flex;
          overflow: auto;
          width: 100%;

          * {
            font-family: "Segoe UI", "Helvetica Neue", Helvetica, "Lucida Grande", Arial, Ubuntu, Cantarell, "Fira Sans", sans-serif !important;
          }
        }

        &_custom {
          .campaigns_container_elements_content {
            overflow: auto;

            .centridesk_users {
              &_filters {
                display: flex;
                gap: 10px;
                padding: 10px 20px;

                .platform_select_simple, .platform_input_search {
                  width: 50%;
                }

                .platform_select_simple:nth-child(2) {
                  margin-top: 30px;
                }

                .platform_input_search {
                  > .MuiFormControl-root {
                    width: 100%;
                  }
                }
              }

              &_table {
                .campaigns_table {
                  width: auto;
                  &_header, &_element {
                    grid-template-columns: 40px repeat(auto-fit, minmax(100px, 1fr));
                  }
                }
              }

              &_placeholders {
                display: flex;
                flex-direction: column;

                > a {
                  cursor: pointer;
                  text-decoration: underline;
                  color: blue;
                  padding: 10px 13px 13px 13px;
                  font-size: 12px;
                }
              }
            }
          }

          .elements_custom {
            padding: 20px;
          }
        }

        &_csv {
          width: 100%;
          position: relative;
          .csv_close {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            right: 0;
            cursor: pointer;

            svg path {
              fill: #8BC34B;
            }
          }

          .csv_reader {
            width: 100%;

            &_content {
              height: 100px;
              width: 100%;
              display: flex;
              align-items: center;
              padding: 0 28px 0 15px;
              border-radius: 5px;
              justify-content: center;
              cursor: pointer;
              outline: 2px dashed #565671;
              flex-direction: column;
              background: #FFF;
              transition: all 0.2s linear;

              &:hover {
                background: rgba(53, 160, 251, 0.2);
                outline: 2px dashed #35A0FB;

                svg {
                  path {
                    fill: #35A0FB
                  }
                }

                span {
                  color: #35A0FB;
                }
              }

              &.active {
                background: rgba(139, 195, 75, 0.3);
                outline: 2px dashed #8BC34B;

                svg {
                  path {
                    fill: #8BC34B
                  }
                }

                span {
                  color: #8BC34B;
                }
              }

              svg {
                width: 50px;
                height: 50px;
              }
            }

            input {
              display: none;
            }
          }
        }
      }

      &_individual{
        overflow: auto;

        .elements_custom {
          padding: 20px;
        }

      }

      &_webhook {
        overflow: auto;

        .elements_custom {
          padding: 20px;
        }
      }

      &_preview {
        height: 100%;
        display: flex;
        overflow: auto;
        flex-direction: column;
      }

      &_footer {
        padding: 10px 20px;
        border-top: 1px solid #f4f4f4;
        display: flex;
        justify-content: center;
        border-left: 1px solid #f4f4f4;
      }
    }
  }
}

.campaigns_preview {
  border-left: 1px solid #f4f4f4;
  height: 100%;
  width: 100%;

  &_container {
    height: 100%;
    padding: 20px 0;
    overflow: auto;
  }

  &_message {
    position: relative;
    display: flex;
    padding: 0 16px;
    align-items: flex-start;

    &_svg {
      height: 16px;
      display: flex;
      position: relative;
      z-index: 1;
      width: 8px;
    }

    &_content {
      box-shadow: 0 1px 0.5px rgba(11, 20, 26, .13);
      background: #FFF;
      position: relative;
      z-index: 2;
      width: 100%;
      left: -1px;
      border-radius: 0 7.5px 7.5px 7.5px;
      color: #111b21;

      .message {
        font-size: 13px;

        &_header {
          font-size: 14px;
          font-weight: 500;
          line-height: 19px;
          color: #111b21;
          margin-bottom: 5px;
          display: flex;

          &.text {
            padding: 6px 7px 0 9px;
          }

          &.image {
            width: 100%;
            padding: 3px 3px 0 3px;

            img {
              width: 100%;
              border-radius: 3px;
            }
          }

          &.document {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px;
            background: #f5f6f6;
            cursor: pointer;
            margin: 2px;
            border-radius: 3px;

            > span {
              display: flex;
              align-items: center;
              grid-gap: 15px;
              gap: 15px;
              font-size: 12px;

              svg {
                width: 20px;
                height: 20px;
                margin-right: 5px;
              }
            }
          }
        }

        &_footer {
          display: flex;
          color: #8696a0;
          line-height: 17px;
          font-size: 12px;
          padding: 0 7px 8px 9px;
        }

        &_content {
          display: flex;
          position: relative;
          overflow-wrap: break-word;
          white-space: pre-wrap;
          padding: 6px 9px 8px 9px;

          &_body {
            > div {
              display: flex;
              line-height: 1.2;
            }
          }

          span {
            overflow-wrap: break-word;
            white-space: pre-wrap;
          }

          &_time {
            visibility: hidden;
          }
        }

        .time {
          float: right;
          margin: -10px 0 -5px 4px;
          position: absolute;
          z-index: 10;
          bottom: 6px;
          right: 6px;

          > div {
            display: flex;
            white-space: nowrap;
            color: #667781;
            height: 15px;
            align-items: center;

            span {
              display: inline-block;
              vertical-align: top;
              font-size: 10px;
            }
          }
        }

      }
    }

    .message_button {
      display: grid;
      gap: 5px;
      padding-left: 7px;
      margin-top: 5px;
      width: 100%;

      > div {
        border-radius: 7.5px;
        box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
        width: 100%;
        background: white;
        height: 36px;
        display: flex;
        gap: 3px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        padding: 0 6px;
        cursor: pointer;

        svg {
          height: 18px;
          width: 19px;
        }

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #009de2;
        }
      }
    }
  }
}

.campaigns_table {
  padding-bottom: 20px;
  min-width: 1000px;
  height: 100%;
  overflow: auto;

  &_header {
    height: 60px;
    border-bottom: 1px solid #f4f4f4;
    display: grid;
    grid-template-columns: 350px repeat(auto-fit, minmax(100px, 1fr));
    width: 100%;
    padding: 0 25px;
    align-items: center;
    font-weight: bold;
    color: black;
    gap: 5px;
  }

  &_elements {
    padding: 0 20px;
    overflow: auto;

    .loading-data {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      height: 100%;
      align-items: center;
    }

    .template_alert {
      margin-top: 20px;
    }
  }

  &_element {
    height: 50px;
    border-bottom: 1px solid #f4f4f4;
    display: grid;
    align-items: center;
    grid-template-columns: 350px repeat(auto-fit, minmax(100px, 1fr));
    width: 100%;
    gap: 5px;
    cursor: pointer;
    padding: 0 5px;
    transition: box-shadow 0.5s linear;

    &:hover, &.active {
      box-shadow: -10px 0 0px #FABF3A;
    }

    span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > div {
      display: flex;
    }

    &_name {
      transition: all 0.5s linear;

      > div {
        display: flex;
        flex-direction: column;
        overflow: hidden;

        span {
          &:nth-child(1) {
            font-weight: bold;
          }

          &:nth-child(2) {
            color: rgba(0, 0, 0, 0.5);
            font-size: 11px;
          }
        }
      }
    }

    &_status {
      .status {
        padding: 4px 25px;
        border-radius: 15px;
        color: white;
        font-weight: 500;
        font-size: 12px;
        height: 26px;

        &.approved, &.finished, &.sent {
          background-color: #8BC34B;
        }

        &.rejected, &.cancelled, &.error {
          background-color: #FF2F56;
          color: white !important;
        }

        &.pending {
          background-color: #FAB74D;
        }

        &.requested, &.in_progress {
          background-color: #ff9800;
        }
      }
    }

    &_phone {
      font-weight: bold;
    }

    &_variables {
      display: flex;
      width: 100%;
      gap: 5px;
      overflow: auto;
      flex-wrap: nowrap;
      text-overflow: ellipsis;

      > span {
        padding: 4px 15px;
        border-radius: 15px;
        color: white;
        font-weight: 500;
        font-size: 12px;
        background: #B5E0EE;
        overflow: initial;
        color: #565671;
      }
    }

    &:last-child {
      border-bottom: inherit;
    }
  }
}

.campaigns_title {
  display: grid;
  line-height: 1.2;

  span {
    &:nth-child(1) {
      font-size: 18px;
      font-weight: 500;
    }

    &:nth-child(2) {
      font-size: 12px;
      color: #000;
      opacity: 0.55;
    }
  }
}

.campaigns_body_samples {
  display: grid;
  gap: 8px;
  margin-top: 20px;

  &_element {
    display: grid;
    align-items: center;
    grid-template-columns: 45px auto;

    span {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px 0 0 5px;
      background: #C1DAEE;
    }

    .platform_input_content {
      max-width: calc(700px - 45px);
    }
  }
}
.cen_tabpanel {
  overflow: auto;
}
.groups_card {
  display: grid;
  grid-template-columns: auto 450px;
  background: #FFF;
  width: 100%;
  border-radius: 15px;
  max-height: 100%;
  overflow: auto;

  .campaign {
    &_header {
      display: grid;
      grid-template-columns: auto auto;
      border-bottom: 1px solid #f4f4f4;
      border-right: 1px solid #f4f4f4;
      padding: 10px 20px;
      min-height: 60px;

      &_actions {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        gap: 10px;
      }

    }

    &_content {
      display: grid;
      grid-template-columns: auto 300px;

      &_info {
        display: grid;
        gap: 10px;
        border-bottom: 1px solid #f4f4f4;
        padding: 10px 20px;
      }

      &_preview {
        display: grid;
        height: 230px;
        overflow: auto;
      }
    }
  }

  .status {
    &_header {
      display: grid;
      border-bottom: 1px solid #f4f4f4;
      padding: 10px 20px;
      height: 60px;
    }

    &_content {
      height: 230px;
      border-bottom: 1px solid #f4f4f4;
      padding: 10px 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &_info {
        padding: 20px;
        display: grid;
        gap: 3px;
        width: 100%;
        border-radius: 5px;

        &_svg {
          width: 50px;
          height: 50px;
          background: white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        span {
          &:nth-child(2) {
            font-size: 20px;
            color: white;
            font-weight: 500;
          }

          &:nth-child(3) {
            font-size: 14px;
            color: white;
            font-weight: 300;
          }

          &:nth-child(4) {
            font-size: 12px;
            font-weight: 500;
            color: black;
            opacity: 0.5;
          }
        }

        &_date {
          display: flex;
          gap: 5px;
        }

        &.pending {
          background-color: #FAB74D;

          svg path {
            fill: #FAB74D;
          }
        }

        &.in_progress {
          background-color: #FF9800;

          svg path {
            fill: #FF9800;
          }
        }

        &.finished {
          background-color: #8BC34B;

          svg path {
            fill: #8BC34B;
          }
        }

        &.cancelled {
          background-color: #FF2F56;

          svg path {
            fill: #FF2F56;
          }
        }
      }

    }
  }

  .details {
    border-right: 1px solid #f4f4f4;

    &_header {
      display: flex;
      border-bottom: 1px solid #f4f4f4;
      padding: 10px 20px;
      height: auto;
      justify-content: space-between;
      align-items: center;
    }

    &_content {
      .campaigns_table {
        min-width: auto;
        &_header, &_element {
          grid-template-columns: 200px 200px auto 200px;
        }
      }

      &_pending {
        border-radius: 15px;
        overflow: hidden;

        > div {
          display: grid;
          justify-content: center;
          padding: 30px 15px;
          text-align: center;

          span {
            &:nth-child(1) {
              font-size: 20px;
            }

            &:nth-child(2) {
              font-size: 12px;
              color: #000;
              opacity: 0.55;
            }
          }
        }
      }
    }
  }

  .chart {
    &_header {
      display: grid;
      border-bottom: 1px solid #f4f4f4;
      padding: 10px 20px;
      height: 60px;
    }

    &_content {
      padding: 10px 20px;

      &_doughnut {
        position: relative;

        .color_inherit {
          color: #F4F4F4;

          svg {
            color: #F4F4F4;
          }
        }

        .color_0 {
          color: #FF4125;

          svg {
            color: #FF4125;
          }
        }

        .color_25 {
          color: #FFAF26;

          svg {
            color: #FFAF26;
          }
        }

        .color_50 {
          color: #4B9CDF;

          svg {
            color: #4B9CDF;
          }
        }

        .color_75 {
          color: #46C47C;

          svg {
            color: #46C47C;
          }
        }

        .chart {
          display: flex;
          justify-content: center;

          span {
            position: absolute;
            margin-top: 70px;
            font-size: 40px;
            font-weight: bold;
          }
        }

        .fields {
          display: grid;
          grid-template-columns: auto auto auto auto;
          column-gap: 12px;
          margin-top: 15px;
          margin-bottom: 15px;
          justify-content: center;

          span:first-child {
            margin-right: 3px;
            width: 14px;
            height: 10px;
            border-radius: 3px;
            content: "*";
            padding: 0 8px;
          }

          span:last-child {
            color: #999999;
            font-size: 10px;
          }

          div:nth-child(1) span:first-child {
            background-color: #FF4125;
          }

          div:nth-child(2) span:first-child {
            background-color: #FFAF26;
          }

          div:nth-child(3) span:first-child {
            background-color: #4B9CDF;
          }

          div:nth-child(4) span:first-child {
            background-color: #46C47C;
          }
        }

        .operation_divider {
          padding: 10px 20px;

          &_header {
            display: grid;
            justify-content: center;
            grid-template-columns: 50% 50%;
            gap: 10px;
            font-size: 13px;

            span:first-child {
              text-align: right;
            }
          }

          &_result {
            display: grid;
            grid-template-columns: 49% 2% 49%;
            justify-content: center;
            gap: 5px;

            span:first-child {
              text-align: right;
            }

          }
        }
      }

      &_pending {
        > div {
          display: grid;
          justify-content: center;
          padding: 30px 15px;
          text-align: center;

          span {
            &:nth-child(1) {
              font-size: 20px;
            }

            &:nth-child(2) {
              font-size: 12px;
              color: #000;
              opacity: 0.55;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1350px) {
  .centribal_card {
    .campaigns {
      &_header {
        grid-template-columns: 100%;

        &_actions {
          display: flex;
          justify-content: flex-start;
        }
      }
    }
  }

  .groups_card {
    grid-template-columns: 100%;

    .campaign_header {
      height: auto;
    }
  }
}

@media (max-width: 700px){
  .centribal_card .campaigns_container_elements{
    grid-template-columns: none;
  }
  .centribal_card .campaigns_container_elements_preview {
    padding-top: 20px;
  }
  .centribal_card .campaigns_header{
    padding: 3px 5px;
  }
  .centribal_card .campaigns_container_elements_custom .elements_custom {
    padding: 3px 5px;
  }
  .centribal_card .campaigns_container_individual .elements_custom {
    padding: 3px 5px;
  }
  .centribal_card .campaigns_container_webhook .elements_custom {
    padding: 3px 5px;
  }
}

@media (max-width: 375px){
  #root .MuiInput-underline {
    padding-top: 7px;
  }
}