#root {
  .business_form {
    position: relative;
    display: grid;
    row-gap: 20px;
    color: #565671;
    z-index: 1;

    .info-tooltip {
      display: grid;
      grid-template-columns: auto 200px;

      > div {
        display: grid;

        &.actions {
          button {
            margin-top: 0;
          }
        }

        > div {
          display: flex;
          align-items: baseline;

          button {
            border-radius: 50%;
            background: #bcbcbc;
            width: 16px;
            height: 16px;
            text-align: center;
            color: white;
            cursor: pointer;
            font-size: 10px;
            margin-left: 10px;
            min-width: inherit;
          }
        }
      }
    }

    .business_element {
      display: flex;
      max-width: 1500px;
      width: 100%;
      justify-content: space-between;

      &.business_grid {
        flex-direction: column;
        row-gap: 20px;

        .elements__grid {
          display: grid;
          row-gap: 10px;
        }
      }

      > div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        line-height: 1.2;

        span:nth-child(1) {
          font-size: 18px;
        }

        span:nth-child(2) {
          font-size: 13px;
          color: #000;
          opacity: 0.55;
        }

        &.code-language {
          background: #f4f4f4;
          padding: 0;
          border-radius: 5px;
          max-width: 450px;

          .MuiInput-input {
            display: flex;
            align-items: center;
          }
        }

      }

      &.business_switch {
        justify-content: space-between;
      }
    }

  }

  .festivities_content {
    display: grid;
    justify-content: center;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    row-gap: 20px;
    column-gap: 30px;

    > .festivity_element {
      position: relative;
      display: grid;
      grid-template-rows: auto 50px;
      background: white;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

      label {
        span {
          font-size: 10px;
        }
      }

      .festivity_switch {
        position: absolute;
        right: 12px;
        bottom: 0;
        color: white;
      }

      div.content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 25px 20px;

        > div > input {
          font-size: 20px;
          color: #565671;
        }

        input {
          color: #565671;
          cursor: text;
          padding: 0;
          height: 21px;
        }

        button {
          padding: 0;
        }

        .MuiSvgIcon-root {
          color: #565671;
        }
      }

      div.actions {
        background: #565671;
        border-radius: 0 0 5px 5px;
        display: flex;
        align-items: center;
        padding: 8px;

        button {
          font-size: 14px;
          min-width: auto;
          margin-top: 0;
          width: 45px;
          height: 45px;
          background: white !important;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

          &.Mui-disabled {
            background: #BDBDBC !important;
          }
        }
      }
    }

    > .festivity_new_element {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px dashed #BCBCBC;
      border-radius: 5px;
      height: 141px;
      cursor: pointer;
    }

    .MuiOutlinedInput-notchedOutline {
      border: inherit;
    }
  }

  .business_content {
    display: grid;
    justify-content: center;
    align-items: flex-start;
    grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));
    row-gap: 15px;
    column-gap: 15px;

    .business_element {
      display: grid;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
      border-radius: 5px;

      > span {
        text-align: center;
        text-transform: capitalize;
        background: #565671;
        color: white;
        padding: 10px;
        border-radius: 5px 5px 0 0;
      }

      svg {
        fill: white;
      }

      > div {
        display: flex;
        align-items: center;
        flex-direction: column;
        background: white;
        border-radius: 0 0 5px 5px;
        padding: 13px;

        hr {
          height: 1px;
          width: 85%;
          margin: 8px;
        }

        > div {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: center;
          gap: 10px;
          width: 100%;

          > .button_actions {
            position: absolute;
            bottom: -45px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 30px;
            z-index: 10;
            background: #565671;
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

            .button_save, .button_delete {
              width: 45px;
              height: 45px;
              background: white !important;
              box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
            }
          }

          .MuiInputBase-root {
            cursor: text;
            padding: 7px 10px;
            text-align: center;
            border: 2px solid #565671;
            border-radius: 20px;

            input {
              color: #565671;
              text-align: center;
              padding: 0;
            }
          }

          > span {
            margin: 5px;
          }
        }


        > button {
          width: 40px;
          height: 40px;
          background: #565671;
          color: white;
          box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
        }
      }
    }
  }

}


@media (max-width: 1150px) {
  #root .business_form .business_element {
    &:nth-child(1) {
      row-gap: 20px;
      grid-template-columns: none;
    }

    &:nth-child(2) {
      grid-template-columns: auto auto;
    }
  }
}


@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
  animation-duration: 0.3s;
}
