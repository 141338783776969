.cen_card {
  h4 {
    margin-top: 0;
  }

  .copy_main {

    &__content {
      display: flex;
      flex-direction: column;

      > span {
        color: #565671;
        font-size: 18px;
        display: flex;
        gap: 8px;
      }

      .p2 {
        font-size: 13px;
        color: #000;
        opacity: 0.55;
        margin-top: 0;
      }

      > div {
        margin-bottom: 10px;
      }
    }

    button {
      cursor: pointer;
    }
  }
}

