.platform_password_input {
  position: relative;

  label {
    width: 100%;
    text-align: left;
    margin-bottom: 5px;
  }

  .platform_password_input_content {
    position: absolute;
    display: grid;
    gap: 8px;
    z-index: 20;
    width: 100%;
    background: white;
    border-radius: 10px;
    margin-top: 10px;
    padding: 10px 30px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;

    > div {
      display: grid;
      align-items: center;

      > span {
        display: flex;
        gap: 10px;
        font-size: 13px;
        text-align: left;

        svg {
          min-width: 20px;
          min-height: 20px;
        }
      }

      .platform_password_input_badges {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        padding-left: 30px;
        flex-wrap: wrap;

        .badge {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          width: 25px;
          background: #565671;
          border-radius: 50%;
          height: 25px;
          line-height: 0;
          color: white;
          box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
        }
      }
    }
  }
}