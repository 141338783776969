// *************** GLOBALS **************
@import './global.scss';
@import url('https://fonts.googleapis.com/css?family=Raleway%3A100%2C200%2C300%2C400%2C500%2C600%2C700%2C800%2C900%2C100italic%2C300italic%2C400italic%2C700italic%7CPoppins%3A100%2C200%2C300%2C400%2C500%2C600%2C700%2C800%2C900%2C100italic%2C300italic%2C400italic%2C700italic%7CPoppins%3A400%2C500%7CRoboto%3A400&subset=latin%2Clatin-ext&display=swap');

body, html, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 14px;
  overflow: auto;
  background: $background;
  font-family: 'Poppins', sans-serif !important;
  color: #565671;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  background-color: inherit !important;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 2px 0;
}
.p-10 { padding: 10px; }
.p-20 { padding: 20px; }
.mb-5 { margin-bottom: 5px; }
*:focus {
  outline: none;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// COLORES
$primary: #565671;
$secondary: #565671;
$green: #88FAB8;
$hover: #BCBCBC;
$error: #FF2F56;
$warning: #FF9966;
$confirm: #69ACE5;
$info_dark: #3296F3;
$info_light: #E8F4FD;
// CLASSES COMUNES COLORES

.error {
  color: $error !important;
}
.confirm {
  color: $confirm !important;
}
.warning {
  color: $warning;
}
.pending {
  color: #5F9EFC;
}
.in_progress {
  color: #ff9800;
}
.finish {
  color: #00e676;
}
.contexts {
  color: #C1DAEE;
  fill: #C1DAEE;
}
.project {
  color: #F8BEB6;
  fill: #F8BEB6;
}
.intents {
  color: #B2E6C8;
  fill: #B2E6C8;
}
.entities {
  color: #FBD17D;
  fill: #FBD17D;
}
.whatsapp_free {
  color: #3DD366;
  fill: #3DD366;
}
.chats {
  color: #FAB74D;
  fill: #FAB74D;
}
.training {
  color: #83BCEB;
  fill: #83BCEB;
}
.users {
  color: #FFAF26;
  fill: #FFAF26;
}
.stats {
  color: #4B9CDF;
  fill: #4B9CDF;
}

.business_hours {
  color: #24BDBD;
  fill: #24BDBD;
}
.default {
  color: $primary;
  fill: $primary;
}
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

// BACKGROUND
#root .bck-contexts {
  background-color: #C1DAEE;
}
#root .bck-project {
  background-color: #F8BEB6;
}
#root .bck-intents {
  background-color: #B2E6C8;
}
#root .bck-entities {
  background-color: #FBD17D;

  path {
    background-color: #FBD17D;
  }

}
#root .bck-chats {
  background-color: #FAB74D;
}
#root .bck-training {
  background-color: #83BCEB;
}
#root .bck-users {
  background-color: #FFAF26;
}
#root .bck-stats {
  background-color: #4B9CDF;
}
#root .bck-integrations {
  background-color: #88FAB8;
}
#root .bck-business_hours {
  background-color: #24BDBD;
}
#root .bck-default {
  background-color: $primary;
}

// PATH
.nlp path {
  fill: #FABF3A;
}
.chatbot path {
  stroke:#FABF3A;
}
.stats path {
  stroke:#FABF3A;
  fill:#FABF3A;
}

// COMÚN
.margin_0 {
  margin: 0;
}
.b-error {
  border: 1px dashed $error !important;
}
* {
  font-family: 'Poppins', sans-serif !important;
  box-sizing: border-box;
}

// Float Buttons
.float_buttons {
  display: flex;
  column-gap: 5px;
  align-items: center;

  .float_buttons_artifacts {
    position: relative;

    .float_toggle {
      border-radius: 100%;
      width: 35px;
      height: 35px;
      color: white;
      text-align: center;
      line-height: 50px;
      transition: all ease-out 200ms;
      transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition-duration: 400ms;
      transform: scale(1.1, 1.1) translate3d(0, 0, 0);
      cursor: pointer;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
      padding: 10px;
      background: #565671;
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: 17px;
        pointer-events: none;

        path {
          fill: white;
        }
      }

      &.active {
        transition-timing-function: linear;
        transition-duration: 200ms;
        transform: scale(0.8, 0.8) translate3d(0, 0, 0);
      }
    }

    .float_item {
      opacity: 0;
      transform: translate3d(0px, 0px, 0px);
      width: 220px;
      height: 40px;
      left: -185px;
      background: transparent;
      transition: all linear 200ms;
      transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
      transition-duration: 180ms;
      position: absolute;
      cursor: pointer;
      top: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      pointer-events: none;
      z-index: 10;

      &.active {
        opacity: 1;
        pointer-events: initial;
      }

      > div {
        background: #565671;
        margin-right: 50px;
        line-height: 25px;
        color: white;
        text-transform: lowercase;
        padding: 0px 20px;
        border-radius: 3px;
        font-size: 12px;
        height: 25px;
        margin-top: 4px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
      }

      > a {
        right: 0;
        background: white;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        position: absolute;
        color: #565671;
        text-align: center;
        cursor: pointer;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 22px;
          height: 22px;
        }
      }
    }

    @for $i from 1 through 9 {
      .float_item_#{$i}.active {
        transform: translate3d(0px, -$i*42px, 0px);
        transition-duration: 90 + $i*100ms;

        &.bottom {
          transform: translate3d(0px, $i*42px, 0px);
        }
      }
    }
  }

  .float_buttons_actions {
    display: flex;
    column-gap: 5px;

    .float_item {
      background: white;
      border-radius: 100%;
      width: 38.5px;
      height: 38.5px;
      line-height: 0;
      color: #565671;
      text-align: center;
      cursor: pointer;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.btn-primary {
  background-color: $primary;
  color: #fff;
  border: 0;
  margin-top: 15px;
  border-radius: 5px;
  font-weight: 400;
  height: 45px;
  cursor: pointer;
  width: auto;
  font-size: 18px;
  min-width: 150px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s, color 0.5s, width 0.5s;

  .MuiCircularProgress-colorPrimary {
    color: white;
  }

  &:hover {
    background-color: $green;
    color: $secondary;
  }

  &.success {
    background-color: $green;
    color: $secondary;

    &:hover {
      background-color: $primary;
      color: #fff;
    }
  }

  &:disabled, &:disabled:hover {
    background-color: grey !important;
    pointer-events: none;
    color: white !important;
  }

  &.create {
    background-color: $green;
    color: $primary;

    &:hover {
      background-color: $primary;
      color: white;
    }
  }

  &.max {
    max-width: fit-content !important;
  }
}
.btn-secondary {
  background-color: #F4F4F4;
  color: #BCBCBC;
  border: 0px;
  margin-top: 15px;
  border-radius: 0.6em 0.6em 0em 0.6em;
  font-weight: 400;
  height: 45px;
  cursor: pointer;
  width: auto;
  padding: 0 15px;
  font-size: 18px;
  transition: background-color 0.5s, color 0.5s, width 0.5s;

  &:hover {
    background-color: $error;
    color: white;
  }
}
.btn-delete, .btn-warning, .btn-confirm {
  color: white;
  border: 0px;
  margin-top: 15px;
  border-radius: 0.6em 0.6em 0em 0.6em;
  font-weight: 400;
  height: 45px;
  cursor: pointer;
  width: auto;
  padding: 0 15px;
  font-size: 18px;
  transition: background-color 0.5s, color 0.5s, width 0.5s;

  &:hover {
    background-color: $primary;
    color: white;
  }

  &:disabled, &:disabled:hover {
    background: grey;
    pointer-events: none;
    color: white;
  }
}
.btn-delete {
  background-color: $error;
}
.btn-warning {
  background-color: $warning;
}
.btn-confirm {
  background-color: $confirm;
}
.btn {
  border: 0px;
  margin-top: 15px;
  border-radius: 0.6em 0.6em 0em 0.6em;
  font-weight: 400;
  height: 45px;
  cursor: pointer;
  width: auto;
  font-size: 18px;
  min-width: 150px;
  padding: 0 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s, color 0.5s, width 0.5s;

  .MuiCircularProgress-colorPrimary {
    color: white;
  }

  &.btn-success {
    background-color: $green;
    color: $secondary;

    &:hover {
      background-color: $primary;
      color: #fff;
    }
  }

  &.btn-info {
    background-color: $info_dark;
    color: $info_light;

    &:hover {
      background-color: $info_light;
      color: $info_dark;
    }

    &.btn-cancel {
      background-color: #F4F4F4;
      color: $primary;
    }
  }
}

//PAGINATION
.MuiPagination-root {
  .MuiPagination-ul {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    .MuiPaginationItem-page.Mui-selected, .MuiPaginationItem-page:hover, .MuiPaginationItem-page.Mui-selected:hover, .MuiPaginationItem-page.Mui-selected.Mui-focusVisible {
      background-color: #565671;
      color: white;

      path {
        color: #565671;
      }
    }

    .MuiTouchRipple-root {
      display: none;
    }

    path {
      color: #565671;
    }

    .MuiPaginationItem-page {
      border-radius: 50%;
      border: inherit;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      background: white;
    }

    li:first-child .MuiPaginationItem-page, li:last-child .MuiPaginationItem-page {
      display: flex;
      justify-content: space-between;
      background-color: inherit;
      box-shadow: initial;
      width: 100%;

      p {
        font-weight: 300;
        color: $primary;
        opacity: 1;
        font-size: 14px;
        margin-right: 5px;
        margin-left: 5px;
      }

      &.Mui-disabled {
        opacity: 1;
      }

      &:hover {
        border: inherit;
      }
    }

    li:last-child .MuiPaginationItem-page {
      flex-direction: row-reverse;
    }

  }
}

//LOADING
.element__progress {
  display: flex;
  justify-content: center;
}

//ALERTS
.MuiAlert-root {
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
  border-radius: 10px 10px 0 10px;
}
.file_container {
  width: fit-content !important;

  a {
    text-decoration: inherit;
  }

  .file_content {
    width: 150px;
    height: 120px;
    border-radius: 20px 20px 0 20px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    display: grid;
    grid-template-rows: auto 35px;
    transition: box-shadow 0.5s linear, background-color 0.5s linear;
    cursor: pointer;


    &:hover {
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      background-color: rgba(19, 47, 95, 0.48);
    }

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div:nth-child(2) {
      background-color: #565671;
      color: white;
      padding: 0 15px;
      display: flex;
      align-items: center;
      font-size: 11px;
      border-radius: 0 0 0 20px;
    }
  }
}
.input-primary, .input-search, .input-secondary, #root .input-secondary {
  border: 0;
  border-radius: 3px;
  padding: 13px;
  text-align: left;
  color: $primary;
  font-size: 14px;
  font-weight: 300;
  width: 100%;
  box-sizing: border-box;
  transition: background-color 0.5s, color 0.5s, width 0.5s;

  input {
    border-radius: 5px;
  }

  .MuiInputBase-multiline {
    padding: 0;
    font-weight: inherit;
    letter-spacing: inherit;

    &::before, &::after {
      content: inherit;
    }
  }

  .MuiInputBase-input {
    color: $primary;
    padding: 0;
  }

  .MuiInput-multiline {
    textarea {
      font-weight: 300;
    }
  }
}
.input-primary, .input-search {
  background-color: #f4f4f4;
}
.input-secondary, #root .input-secondary {
  background-color: white;
}
.input-search {
  max-width: 350px;
  width: 100%;
}
#menu- {
  .MuiMenu-paper {
    color: $primary;

    em {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


    .select-channel-item {
      text-transform: capitalize;
      overflow: hidden;
      white-space: pre-wrap;
    }
  }
}
div[popoverclasses="language-selector"]#menu- {
  z-index: 1300 !important;
}
div[popoverclasses="method-selector"]#menu- .MuiMenu-paper {
  max-width: max-content;
}
div[popoverclasses="header-selector"]#menu- {
  ul {
    li:not(.Mui-disabled) {
      padding: 0 16px;

      div.sandbox_selector, div.soft_delete_selector {
        padding: 6px 0;
      }
    }
  }
}


/* BADGES */
.text-selector {
  display: flex;
  overflow-wrap: break-word;
  flex-wrap: wrap;
  margin-bottom: 20px;

  &.lifespan {
    > div {

      strong {
        margin: 0 5px;
      }

      input {
        width: 20px;
        border-radius: 5px;
        border: none;
        text-align: center;

      }

      input[type=number]::-webkit-outer-spin-button,
      input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }

    }
  }

  button {
    background: transparent;
    border: inherit;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 16px;
      height: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
  }


  > div {
    height: 40px;
    width: fit-content;
    padding: 10px;
    box-sizing: border-box;
    background: rgba(193, 218, 238, 0.6);
    border-radius: 8px;
    margin: 5px;
    display: flex;
  }
}
.sandbox_selector, .soft_delete_selector {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 7px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    &:nth-child(1) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .sandbox_label, .soft_delete_label {
    padding: 5px 10px;
    border-radius: 5px;
    color: white;
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
  }

  .sandbox_label {
    background: #34DC8A;
  }

  .soft_delete_label {
    background: #FF2F56;
  }
}
.input-select {
  width: 100%;
  box-sizing: border-box;
  color: $primary;

  [aria-haspopup="listbox"] {
    color: $primary;
  }

  .Mui-disabled svg {
    display: none;
  }

  /* Multiple Select */
  #select-channel {
    min-width: 125px;
    max-width: 350px;
    height: 45px;
    display: flex;
    align-items: center;
  }

  /* Selectors */
  &.select-bot, &.select-country, &.language-project, &.select-timezone, &.select-secondary {
    #select-bot, #select-country, #language-project, #select-timezone, .select-secondary {
      background: #F4F4F4;
      display: flex;
      align-items: center;
      border-radius: 5px;
      color: $primary;
      font-size: 13px;

      &:focus {
        background-color: #F4F4F4;
      }

      .MuiSelect-select {
        height: 45px;
        display: flex;
        align-items: center;

        &:focus {
          background-color: initial;
        }

        span {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  /* Pagination Intents */

  &.integrations-selector {
    #integrations-selector {
      color: $primary;
      background: #f4f4f4;
      display: flex;
      align-items: center;
      border-radius: 10px;
      min-width: 250px;
    }
  }

  &.language-selector {
    max-width: 200px;

    #language-selector {
      background: #F4F4F4;
      height: 35px;
      font-size: 13px;
      display: grid;
      grid-template-columns: 36px auto;
      grid-column-gap: 6px;
      column-gap: 6px;
      align-items: center;
      border-radius: 10px;
      margin-left: 12px;
      color: $primary;

      &:focus {
        background-color: #F4F4F4;
      }


      svg {
        width: 28px;
      }
    }

  }

  &.methods-selector-training {
    width: 250px;

    #methods-selector-training {
      border: 1.5px solid #D0D0D0;
      border-radius: 0.5em;
      height: 45px;
      display: flex;
      align-items: center;
      color: $primary;

    }
  }

  &.method-selector {

    #method-selector {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      background-color: white;
      border: 1.5px solid #D0D0D0;
      border-radius: 0.5em;
      box-sizing: border-box;
      color: $primary;

      &:focus {
        background-color: white;
      }
    }

    > div > svg {
      right: 7px;
    }
  }


  #input-auth-selector {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: white;
    border: 1.5px solid #D0D0D0;
    border-radius: 0.5em;
    box-sizing: border-box;
    color: $primary;

    &:focus {
      background-color: white;
    }
  }

  #entity-selector {
    height: 35px;
    display: flex;
    align-items: center;
    font-weight: 300;
    color: $primary;
    background: #F4F4F4;
    border-radius: 10px;
    font-size: 12px;
  }

  #language-selector, #language-selector-project {
    background: #F4F4F4;
    height: 35px;
    font-size: 13px;
    display: grid;
    grid-template-columns: 36px auto;
    grid-column-gap: 6px;
    column-gap: 6px;
    align-items: center;
    border-radius: 10px;
    margin-left: 12px;
    color: $primary;

    &:focus {
      background-color: #F4F4F4;
    }


    svg {
      width: 28px;
    }
  }

  &.auth-selector > div, &.status-selector > div {
    margin: 0 !important;
  }

  #auth-selector, #status-selector {
    background: white;
    height: 45px;
    border-radius: 30px;
    margin: 0;
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    color: $primary;

    &:focus {
      background-color: white;
    }
  }

  #input_selector_primary, #input-contexts-selector, #default_phrases-contexts-selector, #output-contexts-selector, #responses-contexts-selector {
    background: white;
    height: 45px;
    border-radius: 8px;
    margin: 15px 0;
    font-size: 14px;
    font-weight: 300;
    display: flex;
    align-items: center;
    color: $primary;

    &:focus {
      background-color: white;
    }
  }

  #input_selector_primary {
    margin: 0;
  }

  #language-selector-project {
    font-size: 14px;
    font-weight: 300;
    height: 45px;
  }

  #header-selector {
    background: white;
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 30px;

    &:focus {
      background-color: white;
    }

  }


  .MuiSelect-select.MuiSelect-select {
    padding: 13px 30px 13px 15px;
    text-align: left;
  }

  svg {
    right: 15px;
    font-size: 1.5em;
    fill: $primary;
    top: inherit;
  }
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.language-selector-item,
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.language-selector-project-item {
  font-size: 13px;

  svg {
    width: 28px;
    margin-right: 5px;
  }
}
li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.method-selector-item {
  display: flex;
  justify-content: center;
}
.opacity_5 {
  opacity: 0.5;
}
h1 {
  color: $secondary;
  font-size: 36px;
  font-weight: 600;
}
h2 {
  color: $primary;
  font-weight: 500;
  font-size: 24px;
  margin-bottom: 0;
  margin-top: 0;
}
h3 {
  color: $primary;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 0;
}
h4 {
  color: $primary;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
h5 {
  color: $primary;
  font-size: 16px;
  margin-top: 30px;
  font-weight: 500;
}
.p2 {
  color: $primary;
  margin-top: 5px;
}
.p3 {
  color: $primary;
  font-size: 16px;
  font-weight: 300;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fw-500 {
  font-weight: 500;
}
.pd-0 {
  padding: 0 !important;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mg-20 {
  margin-bottom: 20px !important;
}
.card {
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 0 0 5px 5px;
  background-color: white;
  padding: 20px 25px;
  box-sizing: content-box;
  margin-bottom: 20px;
  height: fit-content;
  position: relative;
  overflow-y: hidden;
  overflow-x: auto;

  .card-actions {
    display: flex;
    gap: 10px;
    align-items: center;

    button {
      margin-top: 0;
    }
  }

  &.doughnut {
    height: fit-content;
    padding: 30px 0 30px 0;
    display: grid;
    row-gap: 20px;
    justify-content: center;
  }

  &.empty {
    min-height: 550px;
    padding-bottom: 0;
    gap: 20px;

    > div {
      text-align: center;
      line-height: 1.3;
    }
  }

  h3 {
    margin-top: 0;
  }

  .loading-data {
    width: 100%;
    height: 100%;
    min-height: 300px;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiCircularProgress-colorSecondary {
      color: white;
      width: 100px !important;
      height: 100px !important;
    }
  }

  .header-container-extension {
    display: flex;
    justify-content: space-between;
    align-items: center;

    div {
      display: flex;
      align-items: center;

      p {
        margin: 0;
      }

      > span {
        margin-right: 20px;
      }
    }

  }

  .header-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 15px;
    align-items: center;

    button {
      margin-top: 0;
    }

    .header_info {
      display: flex;
      flex-direction: column;
      line-height: 1.2;

      &__tooltip {
        display: flex;
        align-items: center;
        line-height: 0.8;
        gap: 5px;

        button {
          border-radius: 50%;
          background: #565671;
          width: 13px;
          height: 13px;
          text-align: center;
          color: white;
          cursor: pointer;
          font-size: 10px;
          min-width: inherit;

          &.MuiButton-text {
            padding: 6px 6px;
          }
        }

        img {
          width: 80px;
        }
      }
    }

    .profile-icon {
      img {
        width: 159px;
        height: 155px;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      gap: 12px;

      .test_chat {
        display: flex;
        align-items: center;
        gap: 10px;
        text-transform: uppercase;
        letter-spacing: 0.2px;
        font-size: 12px;
        color: rgba(0,0,0,0.54);
        font-weight: 500;
        cursor: pointer;

        svg {
          width: 12px;
          fill: rgba(0,0,0,0.54);
        }
      }

      &.chats-code {
        display: grid;
        grid-template-columns: 50px auto auto;
        justify-content: flex-end;
        align-items: center;

        .view {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.6em 0.6em 0em 0.6em;

          button:first-child {
            margin-left: 0;
          }
        }
      }

      #method-select {
        .select {
          background: #F7F7F7;
          border-radius: 8px;
          height: 50px;
          margin-right: 20px;
        }
      }

      .list-select-header {
        background: #F7F7F7;
        margin-top: -18px;

        &.methods {
          background: white;
        }
      }

      .select {
        min-width: 250px;
      }

      .pdf-svg {
        display: flex;

        button {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .elements__chat_content {
    display: flex;
    justify-content: center;

    .elements__main {
      width: 100%;
      max-width: 1800px;
      max-height: 80vh;
      overflow: auto;

      > div {
        padding: 20px 20px;
      }
    }

    .elements__chat {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background: #EBEBEB;
      padding: 50px;

      .elements__iframe {
        position: relative;
        height: 100%;
        width: 400px;
        border-radius: 25px 25px 0 25px;
        border: inherit;
      }
    }
  }

  .content {
    .table {
      width: 100%;
      border-spacing: 3px;
      table-layout: fixed;

      thead, tbody {

        tr {
          height: 40px;
          text-align: left;

          th, td {
            padding: 0 10px 0 10px;
            overflow: hidden;
            background: #f4f4f4;
          }

          th {
            color: $primary;
            font-weight: 600;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          td {
            color: $primary;
            font-weight: 400;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          td div.text-selector {
            flex-wrap: nowrap;
            padding: 0;
            width: 100% !important;
            margin: 0;
            font-size: 12px;
            overflow: auto;
            scrollbar-color: #C1DAEE transparent;
            gap: 5px;

            &::-webkit-scrollbar {
              height: 6px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: #C1DAEE;
              box-shadow: inset 0 0 10px 10px red;
              border: solid 3px transparent;
            }

            ::-webkit-scrollbar-track {
              box-shadow: inset 0 0 10px 10px green;
              border: solid 3px transparent;
            }

            > div {
              height: 35px;
              padding: 0 6px;
              align-items: center;
              margin: 0;

              > div {
                background: white;
                font-size: 14px;
                border-radius: 50%;
                display: flex;
                align-content: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                margin-left: 6px;

                > span {
                  line-height: 25px;
                }
              }
            }
          }

          td.default-fallback {
            text-align: center;
          }

          td.svg {
            cursor: pointer;
            transition: transform 1s;

            .action-buttons {
              display: grid;
              grid-template-columns: auto auto;
              column-gap: 15px;
              justify-content: center;
            }

            button {
              padding: 0;

              &:disabled {
                svg {
                  visibility: hidden;
                }
              }

            }

            svg {
              &:hover {
                transform: scale(0.9);
                transition: transform 1s;

                path {
                  stroke: #83BCEB;
                }
              }
            }

            &.view svg {
              width: 25px;
              height: 16px;
            }

            &.edit svg, .edit svg, .delete svg   {
              width: 25px;
              height: 25px;
            }

            &.delete svg, .delete svg {
              &:hover {
                path {
                  stroke: $error;
                }
              }
            }
          }

          td.rounded {
            padding-left: 0;

            div:first-child {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                width: 1.5em;
                height: 1.5em;
              }
            }
          }

          td.rounded-deploy {
            padding-left: 0;

            > div {
              display: grid;
              grid-template-columns: 50% auto;
              column-gap: 10px;
              align-items: center;

              div:first-child {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                  width: 1.5em;
                  height: 1.5em;
                  fill: $primary;
                }
              }

              > div:nth-child(2) {
                display: flex;
                justify-content: center;
                cursor: pointer;

                svg {
                  width: 15px;
                  height: 15px;
                }
              }
            }
          }

          th:first-child, td:first-child {
            border-radius: 5px 0 0 5px;
          }

          td, th {
            font-size: 14px;
          }

          td:nth-child(1) {
            font-weight: 500;
          }

          th:last-child, td:last-child {
            border-radius: 0 5px 5px 0;
          }

          &.table-search {
            background: white;

            td {
              font-size: 20px;
            }
          }

        }
      }

      &[class^="table-"] {
        margin-top: 20px;

        .table_noResults {
          border-radius: 50px;
          padding: 20px;
        }
      }

      &.table-contexts {
        td:last-child {
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: auto;
        }

        th:nth-child(2), td:nth-child(2) {
          width: auto;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 50px;
        }

      }

      &.table-actions {
        td:last-child {
          padding-left: 0;
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: auto;
        }

        th:nth-child(2), td:nth-child(2) {
          width: auto;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 250px;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 150px;
        }

        td:nth-child(2) {
          font-weight: bold;
        }

        .table_element__view {
          display: flex;
          gap: 10px;

          > div {
            cursor: pointer;
          }
        }

      }

      &.table-users {
        td:last-child {
          padding-left: 0;
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: 25%;
        }

        th:nth-child(2), td:nth-child(2) {
          width: 35%;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 50%;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 50px;
        }

        td:nth-child(3) span {
          border-radius: 10px;
          padding: 7px 10px 7px 10px;
          font-size: 16px;
          color: white;
          font-weight: 300;
          background-color: #BCBCBC;
          margin-right: 10px;
        }

      }

      &.table-project {
        td:last-child {
          padding-left: 0;
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: 50%;
        }

        th:nth-child(2), td:nth-child(2) {
          width: 50%;
        }

        td:nth-child(2) {
          padding-right: 0;

          > div {
            display: grid;
            grid-template-columns: auto 50px;
            align-items: center;

            > button {
              background-color: white !important;
              border-radius: 0;
            }
          }
        }

        th:nth-child(3), td:nth-child(3) {
          width: 50px;
          text-align: center;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 50px;
          text-align: center;

          .open_window {
            svg {
              width: 20px;
              height: 20px;

              &:hover {
                path {
                  fill: #83BCEB;
                }
              }

              path {
                fill: #565671;
                stroke: #565671;
              }
            }
          }

          &.active {
            background: #F8BEB6;
          }
        }

        th:nth-child(5), td:nth-child(5) {
          width: 50px;
        }

      }

      &.table-intents {
        tr {
          cursor: pointer;
        }

        td:last-child {
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: 250px;
        }

        th:nth-child(2), td:nth-child(2) {
          width: 250px;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 160px;
        }

        th:nth-child(5), td:nth-child(5), th:nth-child(6), td:nth-child(6), th:nth-child(7), td:nth-child(7), th:nth-child(8), td:nth-child(8), th:nth-child(9), td:nth-child(9) {
          width: 75px;
          text-align: center;
          padding: 0;

          > div {
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
              cursor: inherit;
            }

            img {
              width: 25px;
            }
          }
        }

        th:nth-child(10), td:nth-child(10) {
          width: 60px;
        }

        th:nth-child(1), th:nth-child(4) {
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
              height: 25px;
              width: 25px;
              cursor: pointer;
            }
          }
        }

        th {
          svg path {
            fill: #C4C4C4;
            transition: fill 1s;
          }
        }

        th.active {
          svg path {
            fill: $primary;
          }
        }

        th svg:hover path {
          fill: $primary;
        }
      }

      &.table-entities {
        td:last-child {
          padding-left: 0;
          text-align: center;
        }

        th:nth-child(1), td:nth-child(1) {
          width: 300px;
        }

        th:nth-child(2), td:nth-child(2) {
          width: 50%;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 50%;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 60px;
        }

        th:nth-child(1), th:nth-child(3) {

          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 20px;

            svg {
              height: 25px;
              width: 25px;
              cursor: pointer;
            }
          }
        }

        th {
          svg path {
            fill: #C4C4C4;
            transition: fill 1s;
          }
        }

        th.active {
          svg path {
            fill: $primary;
          }
        }

        th svg:hover path {
          fill: $primary;
        }
      }

      &.table-params {

        th, td {
          padding-left: 13px;
        }

        th {
          background-color: #B2E6C8;
        }

        td {
          background-color: white;
        }

        th:nth-child(1), td:nth-child(1) {
          width: 100px;
        }

        th:nth-child(2), td:nth-child(2) {
          width: 25%;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 25%;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 25%;
        }

        th:nth-child(5), td:nth-child(5) {
          width: 25%;
        }

        th:nth-child(6), td:nth-child(6) {
          width: 50px;

          svg {
            width: 24px;
          }
        }

        td:nth-child(1) {
          padding-left: 0;
          text-align: center;
        }

        td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5) {
          padding-left: 10px;
        }

        input {
          background-color: white;
          padding: 5px;
        }

        .select {
          height: 45px;
          padding: 10px 10px;
          font-size: 13px;
          min-width: inherit;
        }

        .list-select-header {
          margin-top: -10px;

          li {
            font-size: 13px;
          }
        }
      }

      &.table-chats {

        th:nth-child(1), td:nth-child(1) {
          width: 50%;
        }

        th:nth-child(2), td:nth-child(2) {
          width: 50%;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 50px;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 50px;
        }

        th:nth-child(5), td:nth-child(5) {
          width: 50px;
        }

        td:nth-child(3), td:nth-child(4), td:nth-child(5) {
          padding-left: 0;
          text-align: center;
        }
      }

      &.table-stats {

        th:nth-child(1), td:nth-child(1) {
          width: auto;
        }

        th:nth-child(2), td:nth-child(2) {
          width: auto;
        }

        th:nth-child(3), td:nth-child(3) {
          width: 100px;
        }

        th:nth-child(4), td:nth-child(4) {
          width: 100px;
        }

        td:nth-child(3), td:nth-child(4) {
          text-align: center;
          padding-left: 0;
        }
      }
    }

    .inputs-display {

      display: grid;
      grid-template-columns: 70% auto;
      grid-gap: 1rem;

      .input {
        display: grid;
        grid-template-columns: 60% auto;
        column-gap: 1rem;
        row-gap: 0.8rem;

        input {
          height: 45px;
        }

        .select-m {
          div.select {
            margin-top: 0;
            width: 100%;
            height: 35px;
            width: -webkit-fill-available;
          }
        }
      }

      &.users {
        .input {
          margin-top: 50px;
        }

        &.create {
          margin-top: 25px;

          .input {
            margin-top: 0;
          }
        }
      }

      &.project_select {
        margin-top: 50px;
        grid-template-columns: 100%;

        .switch {
          justify-content: start;

          > div {
            margin-top: 15px;
          }
        }

        .select {
          height: 45px;
        }
      }

      &.intents {
        margin-top: 25px;

        .main-inputs {
          height: 100%;
          display: grid;

          > div {
            display: grid;
            grid-template-columns: auto auto;
            column-gap: 20px;
          }

          textarea {
            height: 90px;
            margin-top: 10px;
          }
        }
      }

      &.chat {
        margin-top: 50px;
        grid-template-columns: 100%;
      }

      &.room {
        margin-top: 30px;
        grid-template-columns: 100%;
      }
    }

    .icons-display {
      display: grid;
      grid-template-columns: auto auto auto;
      margin: 0 auto;
      width: fit-content;
      column-gap: 30px;

      &.disabled {
        pointer-events: none;
      }

      > div {
        background: #F7F7F7;
        border-radius: 20px;
        padding: 13px 27px;
        width: 200px;
        height: 175px;
        display: grid;
        justify-content: center;
        align-items: center;

        p {
          margin: 0;
          font-size: 16px;
          color: #bcbcbc;
          text-align: center;
        }

        img {
          width: 120px;
          height: 120px;
          cursor: pointer;
        }
      }
    }


    .switch {
      background-color: #f4f4f4;
      border-radius: 20px 20px 0px 20px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      flex-direction: column;

      div {
        display: flex;
        align-items: center;

        p {
          margin: 0;
          color: #565671;
          font-weight: 500;
        }
      }
    }

    .user-projects {
      background: #f4f4f4;
      width: 100%;
      margin-top: 35px;
      border-radius: 20px 20px 0px 20px;
      padding: 18px 15px 15px 25px;

      p.p2 {
        font-weight: 500;
      }

      .projects {
        display: flex;

        div {
          border-radius: 10px;
          padding: 7px 10px 7px 10px;
          font-size: 16px;
          color: white;
          font-weight: 300;
          background-color: #BCBCBC;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .select-h .select {
        max-width: 550px;
        width: 100%;
      }
    }

    .add-extension {
      display: grid;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 25px 0px;
      border-radius: 15px 15px 0 15px;
      background: white;
      margin-top: 15px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      span {
        font-size: 12px;
        color: #FF0045;
      }

      button {
        width: 300px;
        margin: 15px auto 5px auto;
        height: 40px;
        max-width: 230px;
      }
    }

    .insert-items, .variables-extensions {
      margin-top: 20px;

      h4 {
        margin: 15px 0;
      }

      .data_variable {
        position: absolute;
        margin-top: -5px;
        margin-left: 2px;
      }

      > div {
        display: flex;
        margin-bottom: 7px;

        .actions-button {
          display: flex;

          button {
            padding: 7px;

            svg {
              &:hover {
                transform: scale(0.9);
                transition: transform 1s;

                path {
                  stroke: #83BCEB;
                }
              }
            }

            &.delete svg:hover {
              path {
                stroke: $error;
              }
            }
          }
        }

      }

      &.responses {
        > div {
          display: grid;

          .actions-button {
            flex-direction: inherit;
            justify-content: space-between;
          }
        }
      }
    }

    .text-contexts {
      display: grid;
      grid-template-columns: 49% 49%;
      grid-column-gap: 2%;
      margin-top: 50px;
      align-items: flex-start;


      .input, .output {
        padding: 21px 37px;

        .actions-button {
          display: flex;
          flex-direction: column;
          margin: 4px 0;

          button.stroke:hover {
            svg path {
              stroke: #83BCEB;
            }
          }

          button.fill:hover {
            svg path {
              fill: #83BCEB;
              stroke: transparent;
            }
          }

          button.whatsapp:hover {
            svg path {
              fill: #3DD366;
              stroke: transparent;
            }
          }

          svg {
            width: 25px;
            height: 25px;
          }
        }

        .select {
          max-width: initial;
        }
      }

      .trained-phrases {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 15px;

        button {
          margin-top: 0;
          height: 30px;
          font-size: 12px;
          padding: 0 10px;
        }
      }

      > div {
        background-color: #F4F4F4;
        border-radius: 20px 20px 0px 20px;

        h4 {
          font-size: 18px;
          font-weight: 500;
          margin-top: 0;
        }
      }

      .action-add {
        margin-top: 20px;
        border: 0.5px dashed;
        width: 100%;
        height: 48px;
        border-radius: 8px;
        color: #bcbcbc;
        font-size: 16px;
        font-weight: 300;
        cursor: pointer;

        &:disabled {
          cursor: default;
        }
      }
    }

    .text-trained-phrases {
      background-color: #F4F4F4;
      border-radius: 20px 20px 0px 20px;
      padding: 21px 37px;
      margin-top: 50px;

      h4 {
        margin-top: 0;
        font-size: 18px;
        font-weight: 500;
      }

      > p {
        margin-bottom: 10px;
      }
    }

    .training-load {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      svg {
        color: $primary;
      }
    }

    .training-interaction {
      padding: 10px 25px;
      background: #B2E6C8;
      border-radius: 20px 20px 0px 20px;
      margin-top: 25px;
      border-left: 10px solid #FFCB71;
      position: relative;

      > .p3 {
        margin-top: 0;
      }

      .training-progress {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          color: $primary;
        }
      }

      .training-intent {
        background: white;
        padding: 5px 15px;
        border-radius: 5px;
        font-weight: 300;
        border-left: solid 5px #C1DAEE;
        cursor: pointer;
      }
    }

    .training-list-mosaic {
      padding: 20px 0;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
      grid-gap: 1rem;

      div.intent {
        background: #F4F4F4;
        border-radius: 20px 20px 0px 20px;
        padding: 5px 15px;
        border-left: 10px solid #B2E6C8;

        button {
          background: #bcbcbc;
          padding: 4px;
          margin-left: 5px;

          svg {
            width: 20px;
            height: 20px;

            path {
              stroke: white;
            }
          }
        }
      }

      div.add-intent {
        border: 1px dashed #BCBCBC;
        border-radius: 20px 20px 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #bcbcbc;
        cursor: pointer;
      }
    }
  }
}

.elements__main {
  display: grid;
  gap: 10px;

  > p {
    margin-top: 0;
    display: flex;
    gap: 5px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.select {

      div div:nth-child(2) {
        margin: 0;
      }
    }

    &.elements__alert {
      padding: 5px 0;

      > div {
        display: grid;
        grid-template-columns:30px auto;
        justify-content: flex-start;
      }
    }

    > div {
      display: flex;
      max-width: 1200px;
      width: 100%;
      justify-content: space-between;

      > div:first-child {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 0;
        margin-right: 15px;
        width: 100%;

        > span:nth-child(1) {
          font-size: 18px;
        }
      }

      > div:nth-child(2) {
        width: 100%;
        margin: 0;
        text-align: center;
      }

      > p > span {
        padding: 8px;
        border-radius: 20px;
        background-color: $primary;
        color: white;
      }

      .select-h {
        margin: 0;

        .select {
          width: 350px;
          margin: 0;
          border: 1px solid;
          height: 45px;

          span {
            align-items: center;
            display: grid;
            justify-content: center;
            grid-template-columns: auto auto;
            column-gap: 10px;
          }
        }
      }

      .list-select-header {
        margin: 0;
        border: 1px solid;
        border-radius: 20px;
        margin-top: -1px;

        ul {
          padding: 5px 10px;

          li {
            display: flex;
            justify-content: center;

            span {
              display: grid;
              grid-template-columns: auto auto;
              column-gap: 10px;
              align-items: center;

              svg {
                width: 35px;
              }

            }
          }
        }

        ul li:first-child {
          border-top: inherit;
        }
      }

      button {
        margin: 0;
        max-width: 350px;
        width: 100%;
        text-transform: inherit !important;

        &.muicc-colorpicker-button {
          max-width: 40px;
        }
      }
    }

  }

  .actions-null {
    display: flex;
    padding: 15px;
    background: #565671;
    color: white;
    border-radius: 5px;
  }
}

#root {
  .MuiSwitch-root > span {
    color: #FF2F56;

    &:hover {
      background-color: rgba(255, 47, 86, 0.2);
    }

    + .MuiSwitch-track {
      background-color: #FF2F56;
    }

    &.Mui-disabled {
      color: #bdbdbd;

      + .MuiSwitch-track {
        background-color: #bdbdbd;
      }
    }

    &.Mui-checked {
      color: #72D39A;

      &:hover {
        background-color: rgba(114, 211, 154, 0.2);
      }

      + .MuiSwitch-track {
        background-color: #B2E6C8;
      }
    }
  }
}
.grid {
  display: grid;
  justify-content: center;
  align-items: center;
}
.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.fl-space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.MuiDialogContent-root {
  padding: 0 !important;
}
.dialog {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 700px;
  max-height: 500px;
  gap: 10px;
  min-width: 500px;

  .title {
    font-weight: 500;
    color: #565671;
  }

  .description {
    font-weight: 300;
    color: #565671;
    font-size: 16px;
  }

  .svg {
    display: flex;
    justify-content: center;

    svg {
      height: 60px;
      width: 60px;
    }
  }

  .dialog_close {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 28px;
    right: 28px;
    cursor: pointer;

    svg {
      height: 16px;
      width: 16px;
    }
  }
  .button_position {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.btn-container {
  display: grid;
  grid-template-columns: 205px 205px;
  grid-column-gap: 20px;
  column-gap: 20px;
  justify-content: end;
  padding: 0 20px;
  margin-bottom: 15px;
}


// ************** HEADER *************
.free_trial {
  &.plan-header {
    div div:first-child {
      border-bottom: 2px solid #4B9CDF;

      &.compare_plans {
        border-bottom: inherit;
      }
    }
  }

  background-color: #C1DAEE;

  div p {
    .free_trial {
      color: #4B9CDF;
    }

  }
}
.plan_m {
  &.plan-header {
    > div > div:first-child {
      border-bottom: 2px solid #FFAF26;
    }
  }

  background-color: #FAB74D;

  div p {
    .plan_m {
      color: #FFAF26;
    }
  }
}
.professional, .professional_trial {
  &.plan-header {
    > div > div:first-child {
      border-bottom: 2px solid #FF5C35;

      &.compare_plans {
        border-bottom: inherit;
      }
    }
  }

  background-color: #FACFBE;

  div p {
    .professional, .professional_trial {
      color: #FF5C35;
    }
  }
}
.consulting, .enterprise {
  &.plan-header {
    div div:first-child {
      border-bottom: 2px solid #FFAF26;

      &.compare_plans {
        border-bottom: inherit;
      }
    }
  }

  background-color: #f8e0b6;

  div p {
    font-weight: 500;

    .consulting, .enterprise {
      color: #FFAF26;
    }
  }
}
.professional {
  &.plan-header {
    > div > div:first-child {
      border-bottom: 2px solid #FF5C35;

      &.compare_plans {
        border-bottom: inherit;
      }
    }
  }

  background-color: #FACFBE;

  div p {
    &.plan {
      color: #565671;
    }

    &.professional {
      color: #FF5C35;
    }
  }
}
.cen-container.project-none {
  .header > div {
    padding-left: 0;
  }

  .cen-content {
    padding-left: 2% !important;
  }

}

// ************** ACADEMY *************
.academy-videos {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  iframe {
    max-width: 110vh;
    width: -webkit-fill-available;
    width: -moz-available;
    height: 62vh;
    margin-top: 20px;
  }
}
.academy-list_videos {

  display: grid;
  grid-template-columns: auto 410px;
  align-items: flex-start;
  margin-top: 40px;
  column-gap: 30px;

  iframe {
    max-width: 100vh;
    width: -webkit-fill-available;
    width: -moz-available;
    height: 50vh;
  }

  > div {
    display: grid;
    row-gap: 10px;

    > div {
      padding: 10px;
      box-sizing: content-box;
      font-size: 14px;
      cursor: pointer;
      border-radius: 20px;
      background: #F4F4F4;
      max-width: 380px;
      width: 100%;
      transition: background-color 0.5s, color 0.5s, max-width 0.5s;

      &.active {
        max-width: 370px;
      }

      &.active, &:hover {
        background-color: $primary;
        color: white;
      }
    }
  }
}
.academy_actions {
  display: grid;
  grid-template-columns: 150px 150px;
  column-gap: 20px;
}

// ROOT AND UI MATERIAL OVERWRITE
#root .filter-documents > div label, #root .filter-methods > div label {
  color: $primary !important;
}
.MuiPickersToolbar-toolbar {
  background-color: $primary !important;
}
.MuiPickersMonth-monthSelected {
  color: $green !important;
}
#root .MuiInput-underline:hover:not(.Mui-disabled):before, #root .MuiInput-underline:after, #root .MuiInput-underline:before, .dialog .MuiInput-underline:before, .dialog .MuiInput-underline:after {
  border: 0px;
  content: none;
}
#root .MuiTabs-scroller {
  background-color: transparent;
}
#root .MuiTab-textColorPrimary.Mui-selected {
  color: #fff;
  background-color: $secondary;
}
#root .btn:hover {
  background-color: $green !important;
}
.MuiAppBar-colorDefault {
  background-color: #fff !important;
}
#root .cen-btn-create {
  background-color: $primary !important;

  &:hover {
    background-color: $green;
    color: $secondary;
  }
}
#app_bar.MuiAppBar-root {
  box-shadow: none;
  z-index: 1;

  .MuiTabs-scroller {
    overflow-y: hidden;
    overflow-x: auto;
    height: auto;
    scrollbar-width: thin;
  }
  .MuiTab-root, .MuiTabs-root {
    height: auto;
    min-height: 40px;
    font-size: 12px;
    text-transform: inherit;
    font-weight: 400;

    .MuiTouchRipple-root {
      height: 40px;
    }
  }
}
#root .filter-documents > div, #root .filter-methods > div {
  box-shadow: none;
}
.center-filter {
  box-shadow: none;
}

// ************** INTEGRATIONS *************

div.integrations-m .bar-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  box-sizing: border-box;
  min-height: 108px;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;

  .actions {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: auto auto;
    column-gap: 50px;

    .list-select {
      margin-top: 30px;
    }
  }

  a {
    color: #565671;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0;
    text-decoration: none;

    &:nth-child(2) {
      margin-left: 2em;
    }

    &.disabled {
      color: #bcbcbc;

      &:hover {
        color: #565671;
      }
    }
  }
}

.GET, .POST, .PUT, .PATCH, .DELETE {
  border-radius: 3px;
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
  min-width: 45px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  text-align: center;
}
.GET {
  background-color: rgb(222, 235, 255);
  color: rgb(7, 71, 166);
}
.POST {
  background-color: rgb(227, 252, 239);
  color: rgb(0, 102, 68);
}
.PUT {
  background-color: rgb(255, 240, 179);
  color: rgb(23, 43, 77);
}
.PATCH {
  background-color: rgb(172, 243, 224);
  color: rgb(0, 102, 68);
}
.DELETE {
  background-color: rgb(255, 235, 230);
  color: rgb(191, 38, 0);
}
.vn {
  visibility: hidden;
}

.btn-outlined {
  background-color: #fff;
  color: #565671;
  margin-top: 15px;
  border-radius: 0.6em 0.6em 0em 0.6em;
  font-weight: 400;
  height: 50px;
  cursor: pointer;
  width: auto;
  min-width: 120px;
  padding: 0 15px;
  font-size: 16px;
  transition: background-color 0.5s, color 0.5s, font-size 0.5s;
  border: 1px solid #565671;

  &:hover {
    background-color: $green;
    color: $secondary;
    border: 0px;
    font-size: 18px;
  }
}

// ************** MEDIA **************

@media (max-width: 1400px) {
  .stats-container .stats-content .graphics_01 {
    grid-template-columns: inherit;

    > div {
      grid-template-columns: auto auto;
      column-gap: 20px;
    }
  }
}
@media (max-width: 1300px) {
  .stats-container .stats-header {
    display: grid;
    row-gap: 30px;
    justify-content: inherit;

    div.actions {
      display: flex;
      flex-direction: column;
      gap: 5px;

      button {
        width: 100%;
      }
    }
  }

  .item-users {
    margin: 20px 0;
  }

  .dashboard-content .item-container .graphics_02 {
    grid-template-columns: inherit;
    display: flex;
    flex-direction: column;
    align-items: unset;
  }

  .dashboard-content, .item-container {
    display: block !important;
  }
}

// Large devices (desktops, 1400px and down)
@media (max-width: 1400px) {
  .stats-container .stats-header > div:first-child {
    grid-template-columns: inherit;
    justify-content: center;
  }

  .card .header-container {
    grid-template-columns: 100%;
  }
}

@media (max-width: 992px) {
  .stats-container .stats-content .graphics_01 > div {
    grid-template-columns: inherit;
  }

  .MuiDialog-paper {
    transform: scale(0.8);
  }
}

// *************** MATERIAL ***********
.active {
  background: #f2f2f2;
}
#root .MuiLinearProgress-root {
  z-index: 20;
}
#root .MuiListItemIcon-root {
  min-width: inherit;
}
#root .MuiToolbar-regular {
  max-height: 64px;
}
#root .MuiInput-underline {
  border-bottom: 0;

  &:hover {
    &:before {
      border-bottom: 0;
    }
  }
}

#root .MuiInput-underline.Mui-error:after {
  border-bottom-color: #f44336 !important;
}
#root .MuiTabScrollButton-root, #root .MuiTabs-scrollButtons {
  width: 0;
}
#root [class*='PrivateTabScrollButton'] {
  width: 0;
}
#root .MuiTabs-indicator {
  background-color: $main;
}
// *************** APP **************
.cen-app {
  height: 100%;
  display: flex;
  flex-direction: column;

  .cen-container {
    height: 100%;
    background: $background;
    right: 0;
    overflow: hidden;
    position: relative;

    &.project-none {
      width: 100%;
      margin-left: 0;
    }

    .cen-init {
      width: 100%;
      height: 100%;
      background: #F4F4F4;
      color: white;
      font-size: 100px;
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiCircularProgress-colorSecondary {
        color: white;
        width: 100px !important;
        height: 100px !important;
      }
    }

    .cen-content {
      width: 100%;
      background: #F4F4F4;
      position: absolute;
      height: calc(100% - 50px);
      box-sizing: border-box;
      padding: 20px 2%;
      padding-left: calc(90px + 2%);
      overflow-y: auto;

      &.banner {
        height: calc(100% - 95px);
      }

      &.cen-all {
        padding-left: 2%;
      }

      &.cen-maintenance {
        padding: 0;
      }

      &.cen-academy-wordpress {
        padding: 0;
        overflow: hidden;
        background: #E9E9E9;

        .academy_iframe {
          width: 100%;
          height: 100%;
          border: 0;
        }
      }

      .academy-content {
        column-gap: 25px;
        justify-content: center;
        align-items: center;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
        grid-row-gap: 18px;
        grid-column-gap: 18px;

        h3 {
          line-height: 1;
          font-size: 28px;
          text-transform: uppercase;
          font-weight: 600;
        }

        > div.card {
          padding: 30px 30px;
          display: flex;
          min-height: 240px;
          position: relative;
          flex-direction: column;

          > div {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 14px;
            height: 100px;

            img {
              width: 100px;
            }
          }

          button {
            position: absolute;
            bottom: 30px;
            right: 30px;
            background-color: white;
            color: #55FFC2;
            font-size: 16px;
            font-weight: 500;
            border: 1px solid #55FFC2;
            border-radius: 15px 15px 0 15px;
            padding: 0 20px;
            height: 40px;

            &:hover {
              background-color: #55FFC2;
              color: white;
            }
          }
        }
      }

      .upgrade-plan {
        filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
        border-radius: 10px 10px 0 10px;

        .MuiAlert-icon {
          align-items: center
        }

        .MuiAlert-message {
          display: flex;
          align-items: center;

          button {
            font-size: 12px;
            padding: 5px 10px;
            margin: 0px 5px 0px 10px;
            min-width: auto;
            height: auto;
          }
        }
      }

      &.training-mosaic {
        > div {
          display: grid;
          grid-template-columns: auto 340px;
          column-gap: 30px;
          align-items: baseline;
        }
      }

      .doughnut {
        .chart {
          display: flex;
          justify-content: center;

          span {
            position: absolute;
            margin-top: 70px;
            font-size: 37px;
            font-weight: bold;
          }
        }

        .fields {
          display: grid;
          grid-template-columns: auto auto auto auto;
          column-gap: 12px;
          margin-top: 15px;
          justify-content: center;

          span:first-child {
            margin-right: 3px;
            width: 14px;
            height: 10px;
            border-radius: 3px;
            content: "*";
            padding: 0px 8px;
          }

          span:last-child {
            color: #999999;
            font-size: 10px;
          }

          div:nth-child(1) span:first-child {
            background-color:#FF4125;
          }

          div:nth-child(2) span:first-child {
            background-color:#FFAF26;
          }

          div:nth-child(3) span:first-child {
            background-color:#4B9CDF;
          }

          div:nth-child(4) span:first-child {
            background-color:#46C47C;
          }
        }

        .operation {
          padding: 10px;
          background: #F4F4F4;
          border-radius: 15px 15px 0px 15px;
          display: flex;
          justify-content: center;
          width: 100%;
          box-sizing: border-box;

          span {
            font-size: 40px;
            font-weight: 600;
          }
          span:nth-child(2), span:nth-child(3), span.chart_0 {
            color: #bcbcbc;
          }
        }

        .chart_0 { color:#F4F4F4; }

        .chart_1 { color:#FF4125; }

        .chart_2 { color:#FFAF26; }

        .chart_3 { color:#4B9CDF; }

        .chart_4 { color:#46C47C; }
      }
    }
  }
}
// ************* STATS *************
#root [id*='simple-tabpanel'] {
  height: 100%;
  padding: 15px 0 50px 0;
  box-sizing: border-box;
}
span[class^='no-'], div[class^='no-'] {
  position: absolute;
  left: 50%;
  top: 5vh;
  transform: translate(-50%, -50%);
  font-size: 25px;
  width: -moz-fit-content;
  width: fit-content;
  color: #8c8c8c;
  font-weight: bold;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 40px;
}
.MuiDialogActions-root .MuiButtonBase-root {
  color: $main;
}
#root .no-page-found {
  opacity: 1;
  top: 15vh;
}
#root .permissions-access, #root .training-results {
  text-align: center;
    font-size: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    svg {
      font-size: 90px;
    }
}
#root .training-results {
  margin-top: 50px;
}
#root .no-page-found {
  span:first-child {
    font-size: 75px;
  }
  svg {
    position: absolute;
    top: -35px;
    left: -15px;
    font-size: 30px;
  }
}
#root .MuiListItemText-root {
  flex: inherit;
  margin-left: 10px;
}
#root .MuiListItem-gutters {
  padding: 16px;
  cursor: pointer;

  &.disabled {

    span {
      color: $delete;
    }
    path {
      fill: $delete;
    }
  }
}
#root .MuiTabs-root {
  margin-top: -1px;
}
#root .MuiTab-labelIcon {
  min-height: 50px;
}
#root .MuiTab-labelIcon .MuiTab-wrapper > *:first-child {
  margin-bottom: 0;
}
.MuiPickersToolbar-toolbar {
  background-color: $main !important;
}
.MuiPickersMonth-monthSelected {
  color: $main !important;
}
span[class^='no-'].documents {
  top: inherit;
  transform: translate(-50%, 20%);
}

// ************** MEDIA **************

@media (min-width: 600px){
  #root {
    .MuiListItem-gutters {
      .MuiSvgIcon-root {
        font-size: 2rem;
      }
    }

    .cen-content.stats .MuiAppBar-root {
      left: 75px;
    }
  }
}

@media (max-width: 1200px) {
  #root .MuiTabScrollButton-root, #root .MuiTabs-scrollButtons {
    width:40px;
  }
}
@media (max-width: 555px) {
  .dialog {
    min-width: auto;
  }
  .elements__main > div > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .MuiDialog-paper {
    margin: 0 !important;
  }
}

@media (max-width: 700px) {
  .card .content table {
    width: auto;
    padding: 80px;
  }
  .flex{
    align-items: stretch;
  }
  .cen_card .content table {
    width: auto;
  }
  .elements__main > div > div > div:first-child{
    margin-bottom: 10px;

  }
}

@media (max-width: 1300px) {
  .cen-app .cen-container .cen-content {
    padding: 3px 3px 3px 60px;
  }
}

@media (max-width: 500px) {
  .MuiDialogContent-root { 
    width: min-content;
  }
}

@media (max-width: 15000px) {
  .cen_card .content table {
    width: auto;
  }
}