.centribal_academy {
  height: 100%;

  iframe {
    position: relative;
    z-index: 1;
  }

  &_loading {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
  }
}