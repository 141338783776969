.open_button {
  margin: 20px;
  &_container {
    width: 280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    padding: 0 8px;
    min-width: 80px;
    box-shadow: 0 2px 1px -2px rgba(0, 0, 0, 0.2), 0 3px 11px 5px rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
    cursor: pointer;

    span {
      width: 100%;
      text-align: center;
    }

    img {
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }
  }

  &.no_expand {
    .open_button_container {
      width: max-content;
    }
  }
}