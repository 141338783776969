.config_header {
  background-color: white;
  width: 100%;
  height: 60px;
  padding: 0 30px;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F4F4F4;

  .sandbox_container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sandbox_label {
      background: #34DC8A;
      padding: 5px 10px;
      border-radius: 5px;
      color: white;
      margin-left: 10px;
      font-size: 12px;
      font-weight: bold;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
  }

  &__steps {
    .MuiStepper-root {
      padding: 10px;
      cursor: pointer;

      > svg {
        width: 4px;
      }

      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          font-size: 14px;
          font-weight: 400;
        }
      }

      .MuiStepIcon-active {
        fill: #4ECB71;
      }

      .Mui-disabled {
        cursor: pointer;
      }

      .Mui-disabled, .MuiStep-completed {
        svg {
          fill: #BFBFBF;
        }

        .MuiStepLabel-label {
          color: #BFBFBF;
        }
      }
    }
  }

  &__cog {
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      width: 25px;
    }
  }
}

.config_content {
  &_flow {
    position: relative;
    height: calc(100% - 80px);
    width: 100%;
    display: flex;
  }

  &_design {
    position: relative;
    height: calc(100% - 70px);
    width: 100%;
  }
}

/* Design */
.cen_design {
  display: grid;
  overflow: hidden;
  grid-template-columns: auto 450px;
  background-color: white;
  height: 100%;

  .cen_card {
    .content {
      padding: 15px 20px 20px 20px
    }
  }

  &_content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #F4F4F4;

    .container {
      display: grid;
      grid-template-rows: auto 70px;
      height: 100%;
      overflow: auto;

      .cen_tabpanel {
        height: 100%;
        overflow: auto;
      }

      .actions {
        min-height: 70px;
        border-top: 1px solid rgb(244, 244, 244);
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px;
        z-index: 1;

        .MuiAlert-root {
          background-color: #FABF3A;

          .MuiAlert-message {
            padding-right: 15px;
          }
        }

        &_info {
          font-weight: bold;

          .app_bar {
            svg {
              fill: #565671;
            }
          }
        }

        &_buttons {
          display: flex;
          gap: 5px;
          align-items: center;
          justify-content: flex-end;
        }
      }

      .elements_custom {

        &.grid {
          display: grid;
          justify-content: inherit;
        }

        .element__icons {
          display: grid;
          gap: 5px;

          > div {
            border-radius: 5px;
            background: #FFF;
            box-shadow: 0 4px 8px -3px rgba(0, 0, 0, 0.25);
            width: 125px;
            height: 125px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.element__icon_selector {
              height: auto;
              width: 100%;
              display: flex;
              gap: 5px;
              overflow: hidden;

              span {
                padding: 5px;

                svg {
                  width: 30px;
                  height: 30px;
                }
              }
            }

            img {
              width: 85px;
              height: 85px;
            }
          }

          span {
            font-size: 11px;
          }
        }

        .elements__artifacts {
          display: flex;
          gap: 10px;
          width: 100%;
          max-width: 700px;
        }

        .elements__info {
          max-width: 700px;
          width: 100%;
        }

        .elements__timer {
          max-width: 200px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          > span {
            text-align: right;
            font-size: 12px;
          }

          &_content {
            display: flex;
            gap: 10px;

            .platform_input_content {
              width: 65px;

              input {
                text-align: center;
                font-size: 13px;
              }
            }

            .timer {
              padding: 10px 20px;
              background: #f4f4f4;
              border-radius: 3px;
              font-size: 13px;
            }
          }
        }

        .elements__radius {
          display: flex;
          gap: 2px;
          justify-content: end;

          .platform_input_content {
            max-width: 50px;

            input {
              text-align: center;
            }
          }

          .elements__radius_svg {
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            background: #F4F4F4;

            &.direction {
              svg {
                width: 20px;
                height: 10px;
              }
            }

            &.proportion {
              svg {
                height: 15px;
              }
            }
          }
        }

        .elements__themes {
          display: flex;
          gap: 30px;

          &_element {
            display: flex;
            flex-direction: column;
            gap: 5px;
            text-align: center;

            &_selected {
              height: 5px;
              background: #4ECB71;
              width: 60px;
              text-align: center;
              margin: 0 auto;
              margin-top: 5px;
            }

            img {
              cursor: pointer;
              width: 98px;
            }

            span {
              color: #000000;
            }

            &_new {
              display: grid;
              gap: 5px;
              text-align: center;

               span {
                 &:nth-child(1) {
                   width: 98px;
                   height: 150px;
                   border: 0.3px dashed #D9D9D9;
                   display: flex;
                   justify-content: center;
                   align-items: center;
                   font-size: 50px;
                   font-weight: 100;
                   color: #D9D9D9;
                   border-radius: 3px;
                   cursor: pointer;
                 }

                 &:nth-child(2) {
                   color: #000000;
                 }
               }
            }
          }
        }
      }

      .elements_divider {
        display: grid;
        align-items: start;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

        .elements_custom {
          display: flex;
          flex-direction: column;
          align-items: inherit;
          padding: 15px 20px 20px 20px;
          border-bottom: 1px solid #f4f4f4;
          justify-content: flex-start;
          height: 100%;

          &:nth-child(1) {
            border-right: 1px solid #f4f4f4;
          }
        }

        &_container {
          display: flex;
          justify-content: space-between;
          width: 100%;
          padding-right: 20px;

          &.buttons {
            padding-right: 0;

            .elements_divider_colors {
              width: 100%;
            }
          }
        }

        &_colors {
          display: flex;
          flex-direction: column;
          gap: 10px;
        }

        &_icons {
          display: flex;
          flex-direction: column;
          align-items: end;

          .element__icons {
            align-items: end;
            display: flex;
            flex-direction: column;

            svg {
              width: 65px;
              height: 65px;
            }
          }

          .element__icon_switch {
            font-size: 12px;
          }
        }
      }
    }

    #app_bar {
      svg.MuiSvgIcon-root {
        display: none;
      }
    }
  }

  &_widget {
    min-width: 450px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;

    > div {
      overflow: auto;
      height: 100%;
    }
  }
}

.element__switch {
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    width: 11px;
  }
}

.element__colors {
  display: flex;
  align-items: center;
  gap: 20px;

  > span {
    color: rgba(0, 0, 0, 0.50);
  }

  .element__picker {

    .MuiButtonBase-root {
      box-shadow: inherit;
      border: 1px solid #F4F4F4;
      width: 65px;
      height: 65px;
      margin: 0;

      div {
        height: 100%;
        width: 100%;
      }
    }

    input {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .config_header {
    overflow: auto;
  }
}
@media (max-width: 800px) {
  .cen_design {
    display: contents;
  }
  .cen_design_widget > div {
    overflow: auto;
    height: 100%;
    width: auto;
  }
  .cen_card {
    width: auto;
  }
  .cen_design_content .container .cen_tabpanel {
    overflow: visible;
  }
  .cen_design .cen_card .content{
    padding: 14px 20px 20px 10px;
    width: auto;
  }
  .cen_design_content .container .actions_buttons{
    justify-content: center;
  }
}

@media (max-width: 1460px){
  .cen_design_content .container .actions {
    display: grid;
  }
}


