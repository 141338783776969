.mau_table {
  width: 100%;
  margin-top: 20px;
  border-spacing: 0px;
  table-layout: fixed;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
  border-radius: 10px 10px 0 0;

  thead {
    height: 45px;

    tr {
      color: white;

      th {
        background-color: #565671;

        &:first-child {
          border-radius: 10px 0 0 0;
        }

        &:last-child {
          border-radius: 0 10px 0 0;
        }

        div {
          display: flex;
          line-height: 1.0;
          height: 35px;
          flex-direction: column;
          justify-content: center;
          padding-left: 10px;
          span {
            font-weight: 400;
          }

          .table {
            &_title {
              font-size: 14px;
              color: white;
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          background: #f4f4f4;
        }
      }

      td {
        background: white;

        &.table {
          &_calendar {
            border-radius: 0;

            div {
              display: flex;
              gap: 20px;
              font-size: 13px;
              justify-content: center;

              span {
                display: flex;
                align-items: center;
              }
            }
          }

          &_month {
            border-top: 1px solid #565671;
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            border-radius: 0;
          }

          &_channels {
            padding: 0;
            &.values.border {
                > div div:first-child {
                  border-left: 1px solid #565671;
                }
            }

            &.borders {
              border-left: 1px solid #565671;
              border-right: 1px solid #565671;
            }

            &.borderRight {
              border-right: 1px solid #565671;
            }

            > div {
              display: grid;
              align-items: center;

              &.maus, &.sessions {
                grid-template-columns: repeat(5, 16.6%) auto;
              }

              &.alone {
                grid-template-columns: 100%;

                .pointer {
                  cursor: pointer;
                }
              }

              > div {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;

                &.total {
                  background: #f4f4f4;
                  font-weight: bold;
                }
              }
            }

            &.values {
              > div {
                > div {
                  border-top: 1px solid #565671;
                  border-right: 1px solid #565671;
                }
              }
            }
          }
        }


        &:last-child {
          > div div {
            &:first-child {
              border-left: 0 !important;
            }

            &:last-child {
              border-right: 0 !important;
            }
          }
        }
      }
    }
  }

  tr {
    height: 35px;
    text-align: left;

    th:first-child {
      width: 200px;
    }

    th:nth-child(4), th:nth-child(5){
      width: 135px;
    }
  }

  td {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &.mau_sunco {
    .maus, .sessions {
      grid-template-columns: inherit !important;
    }

    th:last-child {
      width: inherit;
    }
  }
}

@media (max-width: 1060px){
  .mau_table {
    width: max-content;
  }
}