// Node Selected
.is_sending {
  cursor: progress;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 10000;
}

.react-flow__node {
  background-color: white;
  color: #565671;
  border-radius: 10px;
  min-width: 150px;
  width: 100%;
  max-width: 250px;
  // TODO controlar si el nombre del intent ha cambiado para hacer la petición
  // TODO phrases de intent inicial
  &.selected .flow_element {

    &.intent {
      outline: 1px solid #565671;
      border-top: 5px solid #565671;
    }

    &.intent_initial {
      outline: 1px solid #68ABE4;
      border-top: 5px solid #68ABE4;
    }

    &.intent_df {
      outline: 1px solid #FF5A34;
      border-top: 5px solid #FF5A34;
    }
  }

  .flow_element {
    border-radius: 2px;
  }
}

// Node Types --> INTENT
.react-flow__handle-left {
  cursor: inherit;
  width: 100%;
  height: 100%;
  z-index: 0 !important;
  border-radius: 0;
  left: 0;
  background: transparent;

  &.target_node {
    z-index: 100 !important;
  }
}

.intent_initial_divider {
  background: white;
  height: 10px;
  cursor: auto;
  position: relative;
  z-index: 10;
}

.flow_element {
  display: grid;
  height: 100%;
  background: #f4f4f4;
  font-size: 12px;
  position: relative;
  outline-offset: -1px;

  &__initial {
    &_content {
      display: flex;
    }
  }

  &__dragged {
    position: absolute;
    z-index: 11;
    height: 100%;
    width: 100%;
  }

  &__drag {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.intent {
    border-top: 5px solid #565671;
  }

  &.intent_initial {
    border-top: 5px solid #68ABE4;

    &.responses {
      border-top: 0 !important;
      background: white;
      cursor: auto;
    }
  }

  &.intent_df {
    border-top: 5px solid #FF5A34;
  }

  &__actions {
    position: absolute;
    top: -25px;
    display: flex;
    gap: 1px;
    font-size: 12px;

    &.intent {
      span {
        background-color: #565671;
        color: white;
      }
    }

    &.intent_initial {
      span {
        background-color: #68ABE4;
        color: white;
      }
    }

    &.intent_df {
      span {
        background-color: #FF5A34;
        color: white;
      }
    }

    span {
      border-radius: 5px 5px 0 0;
      padding: 5px 10px;
      background: #565671;
      color: white;
      cursor: pointer;

      &.disabled {
        background-color: #F4F4F4;
        color: #565671;
        pointer-events: none;
      }
    }
  }

  &__drag {
    height: 15px;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    height: 20px;
    padding: 0 7px;
    border-radius: 3px 3px 0 0;
    background: #f4f4f4;
  }

  &__body {
    padding: 7px;
    background-color: #565671;
    border-radius: 3px;
    cursor: auto;

    .response {
      display: grid;
      gap: 7px;

      .title {
        color: white;
        font-size: 12px;
      }
    }

    .list-container {
      margin-top: 40px;
    }

    .list-item {
      display: flex;
      align-items: center;
      background-color: #FFD2D2;
      padding: 0px 20px;
      margin-top: 15px;
      cursor: move;

    }

    .list-item i {
      margin-right: 20px;
      font-size: 30px;
    }

    h3 {
      font-size: 30px;
    }

  }

  div[data-rbd-droppable-id="droppable"] {

    > div {
      height: 40px !important;
    }
  }

  &__text {
    display: grid;
    position: relative;
    grid-template-columns: auto 15px;
    background: #fff;
    border-radius: 2px;
    padding: 5px;

    &:hover {
      .flow_element__draggable_handle {
        display: flex;
      }

      .flow_element__component {
        display: flex;
      }
    }

    > span {
      height: 24px;
      display: flex;
      align-items: center;
    }
  }

  &__draggable_handle {
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 15px;
    cursor: grab;
    display: none;
  }

  &__button {
    display: grid;
    position: relative;

    &:hover {
      .flow_element__draggable_handle {
        display: flex;
      }

      .flow_element__component {
        display: flex;
      }
    }
  }

  &__handle {
    display: grid;
    grid-template-columns: auto 15px;
    position: relative;
    border-radius: 2px;
    height: 35px;
    width: calc(100% + 14px);
    overflow: hidden;

    > span {
      padding: 6px 0 7px;
    }

    .react-flow__handle {
      position: absolute;
      background-color: #F4F4F4;

      &.is_disabled {
        background-color: #C4C4C4;
      }

      &.is_connected {
        pointer-events: none;
        background-color: #4ECB71;
      }
    }


  }

  &__inputs {
    height: 35px;
    background-color: #FABF3A;
    border-radius: 2px;

    .platform_input_content {
      &:nth-child(1) {
        height: 15px;

        .platform_input {
          min-height: 15px;
          padding: 0 5px !important;
        }

        input {
          font-size: 11px;
        }
      }

      &:nth-child(2) {
        height: 20px;

        .platform_input {
          min-height: 20px;
          padding: 0 5px !important;
        }

        input {
          font-size: 11px;
        }
      }

      input {
        padding: 0;
      }
    }
  }

  &__component {
    position: absolute;
    display: none;
    height: 100%;
    align-items: center;

    &_delete {
      left: -50px;
      padding-right: 20px;
    }

    &_add {
      right: -50px;
      padding-left: 20px;
    }

    > span {
      padding: 5px 10px;
      border-radius: 5px;
      background: #FABF3A;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
      height: 35px;

      &.is_disabled {
        pointer-events: none;
        opacity: 0.7;
      }

      svg {
        width: 22px;
        height: 18px;
      }
    }
  }

  .platform_input {
    min-height: 24px;
  }

  .MuiInput-root, .platform_input {
    background-color: inherit;
    padding: 0 !important;
  }

  input, textarea {
    font-size: 12px;
    line-height: 1.1;
  }

}

.flow_element_selected {
  &__title {
    display: flex;
    color: #565671;
    padding: 2px;
    transition: background-color 1s, color 1s;

    &.context {
      background-color: #C1DAEE;
    }

    &.intent {
      background-color: #B2E6C8;
    }

    &.intent_initial {
      background-color: #68ABE4;
    }

    &.intent_df {
      background-color: #FF5A34;
    }

    &.custom {
      background-color: #FABF3A;
    }

    span {
      margin: 0 auto;
    }

    &:first-child {
      border-radius: 5px 5px 0 0;
    }
  }

  span {
    display: flex;
    padding: 2px;
  }

  &.context {

  }
}

// Sidebar
.flow_menu {
  padding: 8px;
  background-color: #565671;
  color: white;
  max-width: 260px;
  width: 100%;
  position: relative;
  z-index: 4;
  margin-top: 20px;
  height: 100%;
  border-radius: 0 15px 15px 0;
  box-shadow: -2px 2px 3px #00000029;

  &_elements {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &_test {
    position: absolute;
    bottom: 5px;
    font-size: 12px;
    cursor: pointer;
  }

  &_actions {
    display: grid;
    gap: 10px;

    .drop_element {
      background: white;
      border: 1px solid #F0F2F8;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 40px auto;
      align-items: center;
      cursor: grab;
      transition: all 1s;

      &.is_disabled {
        pointer-events: none;
        opacity: 0.7;
      }

      &_img {
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 25px;
          height: 25px;
        }
      }

      &_text {
        height: 100%;
        font-size: 12px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        background: #F0F2F8;
        color: #565671;
        overflow: hidden;

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}

// Edges
.react-flow__edge-path,
.react-flow__connection-path {
  stroke: #4ECB71;
  stroke-width: 1;
  fill: none;
}
