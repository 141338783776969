.accordion__delete_header {
  display: flex;
  align-items: center;
  column-gap: 8px;
  height: 31px;

  svg {
    width: 21px;
    height: 21px;

    path {
      stroke: #FF2F56;
    }
  }
}

.accordion__header_polls {
  display: flex;
  column-gap: 50px;
  flex-wrap: wrap;
  row-gap: 5px;

  > div {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 30px;

    &:first-child {
      width: 210px;
    }

    span {
      &:nth-child(2) {
        display: flex;
        align-items: center;
        opacity: 0.8;

        span:nth-child(1) {
          margin-right: 10px;

        }

        svg {
          width: 15px;
        }
      }
    }

    .checkbox {
      .MuiRadio-colorSecondary {
        padding: 0;
      }
    }
  }
}

.accordion__content {
  width: 100%;

  .accordion__element_text {
    display: grid;
    row-gap: 10px;

    .accordion__element_artifacts {
      display: grid;
      grid-template-columns: auto 38px;
      column-gap: 12px;
    }

    span {
      text-align: left;
      color: #565671;
    }

    .input-accordion {
      background-color: #FFFFFF;
      min-height: 40px;
      color: #565671;
      border-radius: 5px;

      .MuiInputBase-multiline {
        padding: 14px 21px;
        color: #565671;
      }

      textarea {
        max-width: 100%;
      }

      &.polls {
        min-height: auto;

        input {
          padding: 12px 15px;
        }
      }
    }
  }

  .accordion__element_param {
    display: grid;
    background: white;
    border-radius: 5px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

    &_header {
      display: grid;
      grid-template-columns: auto 100px;
      background: #B2E6C8;
      border-radius: 5px 5px 0 0  ;

      &_content {
        display: flex;
        align-items: center;

        > span {
          width: 30px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #F4F4F4;
        }
      }

      &_actions {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 0 18px;
        justify-content: space-between;

        div {
          cursor: pointer;

          &:nth-child(1) {
            svg {
              width: 25px;
              height: 20px;
            }
          }

          &:nth-child(2) {
            svg {
              width: 22px;

              path {
                stroke: #FF2F56;
              }
            }
          }
        }
      }
    }
  }
}

.accordion__element_contexts {
  display: grid;
  gap: 10px;

  .elements__chips {
    width: 100%;

    .MuiAlert-root {
      height: 40px;
      padding: 0 10px;
      align-items: center;
      margin-top: 0;
    }
  }

  .elements__contexts {
    display: flex;
    gap: 10px;
    min-width: 500px;
    width: 500px;
  }

  &_flex {
    display: flex !important;
    align-items: center;
    gap: 10px;

    .elements__chips {
      > div {
        width: 100%;
      }
    }
  }
}

.accordion__element {
  &_events {
    display: grid;
    gap: 10px;

    .platform_input_content {
      max-width: 550px;
    }

    .elements__chips {
      width: 100%;
    }
  }

  &_extension {
    display: grid;
    gap: 10px;

    .MuiInputBase-input {
      font-size: 12px;
    }

    &_header {
      display: flex;

      .platform_select_simple {
        width: 120px;

        .MuiSelect-selectMenu {
          border-radius: 5px 0 0 5px;
        }
      }

      .platform_input_content {
        .platform_input {
          border-radius: 0 5px 5px 0;
        }
      }
    }

    &_tabs {
      &_panels {
        display: grid;
        grid-template-columns: repeat(auto-fill, 130px);
        gap: 10px;
      }

      &_panel {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        height: 40px;
        cursor: pointer;
        transition: all 0.2s linear;

        span {
          font-weight: 500;
          font-size: 12px;
          color: #565671;
        }

        &.active {
          background: #FFFFFF;
          border-radius: 5px 5px 0 0;
        }
      }

      &_elements {

      }

      &_element {
        background: #FFFFFF;
        border-radius: 5px;
        padding: 15px;
      }
    }

    &_prompt {
      display: grid;
      gap: 10px;

      &_content {
        display: grid;
        align-items: center;
        grid-template-columns: auto 35px;
        gap: 15px;

        .float_buttons {
          .float_toggle {
            transform: scale(0.9) !important;
          }
        }
      }
    }

    &_body {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &_content {
        display: flex;
        flex-direction: column;
        gap: 10px;

        &_payload {
          display: grid;
          grid-template-columns: 200px auto 40px;
          gap: 5px;
          align-items: center;

          .platform_input_content_delete {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            svg {
              width: 22px;

              path {
                stroke: #FF2F56;
              }
            }
          }
        }
      }
    }

    &_auth {
      &.no_authentication, &.bearer {
        display: grid;
        grid-template-columns: 250px auto;
        gap: 5px;

        .MuiAlert-root {
          filter: inherit;
        }
      }

      &.basic {
        display: grid;
        grid-template-columns: 250px auto auto;
        gap: 5px;
      }

      &.jwt {
        display: grid;
        gap: 5px;

        .jwt_header {
          display: grid;
          grid-template-columns: 110px auto;

          .MuiSelect-selectMenu {
            border-radius: 5px 0 0 5px;
          }

          .MuiFormControl-root {
            border-radius: 0 5px 5px 0;
          }
        }

        .jwt_content {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 5px;

          .jwt_access_key {
            position: relative;

            .platform_input__primary:before {
              content: "$.";
              position: absolute;
              left: 0;
              height: 100%;
              width: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #B2E6C8;
              color: #565671;
              border-radius: 5px 0 0 5px;
              font-size: 14px;
              font-weight: 500;
            }

            #jwt_access_key {
              padding-left: 20px !important;
            }

          }
        }

        .jwt_headers {
          display: grid;
          gap: 5px;
        }
      }
    }

    &_headers {
      display: grid;
      gap: 5px;

      &_element {
        display: grid;
        grid-template-columns: auto auto 50px;
        gap: 5px;

        &_delete {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            width: 25px;

            path {
              stroke: #FF2F56;
            }
          }
        }
      }
    }
  }

  &_variables {
    .accordion__element_header {
      display: grid;
      margin-bottom: 10px;
    }

    .accordion__element_content {
      display: grid;
      row-gap: 15px;

      .accordion__element_variable {
        display: grid;
        background: white;
        border-radius: 5px;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        overflow: hidden;

        &_header {
          display: grid;
          grid-template-columns: auto 100px;
          background: #B2E6C8;

          &_content {
            display: flex;
            align-items: center;

            > span {
              width: 30px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #F4F4F4;
            }
          }

          &_actions {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 0 18px;
            justify-content: space-between;

            div {
              cursor: pointer;

              &:nth-child(1) {
                svg {
                  width: 25px;
                  height: 20px;
                }
              }

              &:nth-child(2) {
                svg {
                  width: 22px;

                  path {
                    stroke: #FF2F56;
                  }
                }
              }
            }
          }
        }

        &_content {
          display: grid;
          gap: 5px;
          padding: 20px;

          &_custom_grid {
            display: grid;
            grid-template-columns: 200px auto 35px;
            align-items: center;
            gap: 5px;
          }
          
          &_equivalences {
            display: grid;
            grid-template-columns: 200px auto 30px;
            align-items: center;
            gap: 10px;

            .platform_input_content_delete {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              padding-left: 0px;
              svg {
                width: 22px;

                path {
                  stroke: #FF2F56;
                }
              }
            }
          }

          &_custom {
            display: grid;
            grid-template-columns: auto;
            align-items: center;
            gap: 5px;
            
            .actions {
              display: flex;
              grid-gap: 5px;
              gap: 5px;
                .platform_btn.btn_icon {
                background: #f4f4f4;
                min-width: 35px;

                svg {
                  width: 12px;
                  height: 12px;
                }
              }
            }
            

            .platform_input_content_delete {
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              padding-left: 10px;
              svg {
                width: 22px;

                path {
                  stroke: #FF2F56;
                }
              }
            }
          }
          .accordion__element_parameter_equivalence {
            display: flex;
            gap: 10px;

            .input-primary {
              padding: 10px 15px !important;
              max-width: 160px;
            }

            input {
              font-size: 12px;
            }

            .delete_equivalence {
              min-width: 35px;
              height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #F4F4F4;
              border-radius: 5px;
              cursor: pointer;

              svg {
                width: 12px;
                height: 12px;
              }
            }
          }
        }

        .accordion__element_header {
          display: grid;
          grid-template-columns: auto 25px;
          column-gap: 10px;
          height: 55px;
          background: #B2E6C8;
          color: #565671;
          border-radius: 5px 5px 0 0;
          padding: 5px 20px;

          .platform_input {
            min-height: 25px;
            padding: 0 !important;
          }

          > div {
            &:nth-child(1) {
              display: grid;
              line-height: 18px;

              .error {
                &::after {
                  border: 1px solid #FF2F56 !important;
                  transform: scaleX(1);
                }
              }

              input {
                font-size: 14px;
                padding: 0;
                color: #565671;


                &::placeholder {
                  color: #565671 !important;
                  opacity: 0.6;
                }
              }

              span {
                font-size: 11px;
              }
            }

            &:nth-child(2) {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: -5px;
              cursor: pointer;
            }
          }
        }

        .accordion__element_content {
          display: grid;
          row-gap: 7px;
          padding: 10px 30px;

          .accordion__element_equivalences {
            display: grid;
            grid-template-columns: 200px auto;
            column-gap: 15px;
            padding: 0 40px;
            position: relative;

            input {
              font-size: 12px;
            }

            .platform_input_content_delete {
              position: absolute;
              right: -8px;
              width: 50px;
              visibility: hidden;
              padding: 5px;
              transition: visibility 0.8s linear;

              svg {
                width: 24px;

                path {
                  stroke: #FF2F56;
                }
              }

            }

            &:hover {
              button {
                visibility: visible;
              }
            }

          }
        }

        .accordion__element_actions {
          display: grid;
          grid-template-columns: 200px 1px 200px;
          justify-content: center;
          align-items: center;
          padding: 15px 0;

          button {
            margin-top: 0;

            &.action_add {
              font-size: 12px;
            }
          }

          > span {
            width: 1px;
            height: 100%;
            background: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }
  }

}

@media (max-width: 880px) {
  .accordion__element_contexts_flex .elements__chips > div {
    width: max-content;
  }
}