.dashboard {
  height: 100%;
  display: grid;
  grid-template-rows: 50px 40px auto;
  max-width: 1300px;
  margin: 0 auto;
  background: white;
  border-radius: 5px;
  overflow: hidden;

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

    span {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &_content {
    padding: 10px;
    height: 100%;
    overflow: auto;

    > div {
      display: flex;
      flex-direction: column;
      gap: 5px;
      overflow: auto;

      &[hidden] {
        height: 0;
      }
    }

    .dashboard_loading {
      height: 300px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .dashboard_feedback {
      display: grid;
      gap: 10px;
      justify-content: center;
      align-items: center;

      span {
        text-align: center;
      }
    }

    .dashboard_elements {
      display: grid;
      grid-template-columns: 250px auto 280px 50px 50px 160px;
      align-items: center;
      gap: 5px;
      border-radius: 5px;
      padding: 0;
      height: 40px;
      overflow: hidden;
      cursor: pointer;

      &.disabled {
        cursor: inherit;

        .dashboard_element {
          &.name {
            background-color: #FF2F56;
            color: white;
          }

          &.links {
            > div > span {
              cursor: pointer;
            }
          }
        }
      }

      .dashboard_element {
        display: flex;
        align-items: center;
        padding: 0 10px;
        height: 100%;
        background-color: #F4F4F4;
        font-weight: 400;
        font-size: 14px;
        overflow: hidden;

        > span {
          width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &.name {
          background-color: #B2E6C8;
        }

        &.lang, &.sandbox {
          text-align: center;
          text-transform: uppercase;
        }

        &.sandbox {
          &.active {
            background-color: #B2E6C8;
          }
        }

        &.channels {
          > span {
            display: flex;
            justify-content: end;

            > span {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              width: 37px;

              svg {
                width: 18px;
                height: 18px;
              }
            }

          }
        }

        &.links {
          > div {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            > span {
              display: flex;
              justify-content: center;
              align-items: center;
              height: 40px;
              width: 40px;
            }
          }
        }
      }
    }
  }

  header {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}

#root {
  .MuiAccordion-root {

    .dashboard_elements {
      width: 100%;
    }
  }

  .MuiAccordion-root::before {
    display: none;
  }
}

@media (max-width: 460px) {
  .dashboard_header {
    display: grid;
  }
  .dashboard {
    grid-template-rows: 95px 41px auto;
  }
}