@import 'styles/global.scss';

#root {
  .MuiDivider-root {
    background-color: #F4F4F4;
  }
  .cen-verify-header {
    height: 75px;
    background-color: $main;

    .cen-verify-title {
      font-size: 55px;
      position: absolute;
      width: 100%;
      text-align: center;
      top: 8px;
    }
  }
  header {
    box-shadow: 0px 4px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  }

  .cen-verify-content {
    margin: 20px;

    .cen-action-card {
      max-width: 500px;

      .cen-action-header {
        grid-template-columns: none;

        h1 {
          text-transform: none;
        }

        svg {
          margin: 0 auto;

        }

      }

      .cen-action-body {
        text-align: center;
      }
    }
  }

  .cen-verify-icon {
    width: 120px;
    position: relative;
    left: 15px;
    cursor: pointer;
    z-index: 2;
  }

  .cen-verify-return {
    position: absolute;
    right: 50px;
    top: 22px;
    z-index: 1200;
    color: white;
    border: 1px solid white;
    min-width: 115px;
  }
}

@media (max-width:1250px){
  .landing-verify {
    .img_verify img {
      max-height: 50px;
      height: auto;
    }

    div h1 {
      font-size: 20px;
    }

    p {
      font-size: 12px;
    }
  }
}