.ws_header {
  height: 40px;
  background: #FABF3A;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
}

.ws_subheader {
  background: #F4F4F4;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  height: 30px;
  align-items: center;
  font-size: 18px;

  > div {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  div:nth-child(1) {
    justify-content: flex-end;
    border-right: 2px solid #565671;

    span {
      padding-right: 10px;
    }
  }

  div:nth-child(2) {
    span {
      padding-left: 10px;
      font-weight: bold;
      font-size: 20px;
    }
  }
}

.ws_table {
  display: grid;
  width: 100%;

  &_header {
    background: #565671;
    color: white;
    display: grid;
    grid-template-columns: 25% 25% 50%;
    text-align: center;

    div span {
      font-size: 16px;
      font-weight: 400;
    }
  }

  &_body {
    max-height: 350px;
    overflow: auto;

    &__element {
      display: grid;
      grid-template-columns: 25% 25% 50%;
      text-align: center;

      &:nth-child(even) {
        background-color: #f4f4f4;
      }

      span {
        text-align: center;
        font-size: 16px;
      }
    }
  }

}