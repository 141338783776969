.card {
  .actions {
    .platform_date_picker_campaigns {
      display: flex;
      flex-direction: column;
      align-items: center;

      > span {
        width: 100%;
        font-size: 10px;
      }

      > div {
        cursor: pointer;
        width: 130px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        grid-gap: 10px;
        background: #f4f4f4;
        border-radius: 5px;
      }
    }

    .date_picker_campaigns {
      display: none;
    }
  }

  .content {
    display: grid;
    gap: 15px;
  }
}

.campaigns_view {
  display: grid;
  grid-template-columns: auto 350px;
  gap: 30px;

  &_info {
    display: flex;
    flex-direction: column;

    table {
      margin-top: 0;
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &_title {
      display: grid;
      line-height: 1.2;

      span {
        &:nth-child(1) {
          font-size: 18px;
          font-weight: 500;
        }

        &:nth-child(2) {
          font-size: 12px;
          color: #000;
          opacity: 0.55;
        }
      }

      .date {
        display: flex;
        gap: 6px;
        margin-top: 3px;

        span {
          font-size: 10px;
          color: #000;
          opacity: 0.55;
        }
      }
    }


    &_actions {
      display: flex;
      gap: 10px;
    }
  }

  &_doughnut {
    background-color: white;
    border-radius: 15px;
    padding-top: 15px;

    .color_inherit {
      color: #F4F4F4;

      svg {
        color: #F4F4F4;
      }
    }

    .color_0 {
      color: #FF4125;

      svg {
        color: #FF4125;
      }
    }

    .color_25 {
      color: #FFAF26;

      svg {
        color: #FFAF26;
      }
    }

    .color_50 {
      color: #4B9CDF;

      svg {
        color: #4B9CDF;
      }
    }

    .color_75 {
      color: #46C47C;

      svg {
        color: #46C47C;
      }
    }


    .chart {
      display: flex;
      justify-content: center;

      span {
        position: absolute;
        margin-top: 70px;
        font-size: 40px;
        font-weight: bold;
      }
    }

    .fields {
      display: grid;
      grid-template-columns: auto auto auto auto;
      column-gap: 12px;
      margin-top: 15px;
      margin-bottom: 15px;
      justify-content: center;

      span:first-child {
        margin-right: 3px;
        width: 14px;
        height: 10px;
        border-radius: 3px;
        content: "*";
        padding: 0 8px;
      }

      span:last-child {
        color: #999999;
        font-size: 10px;
      }

      div:nth-child(1) span:first-child {
        background-color: #FF4125;
      }

      div:nth-child(2) span:first-child {
        background-color: #FFAF26;
      }

      div:nth-child(3) span:first-child {
        background-color: #4B9CDF;
      }

      div:nth-child(4) span:first-child {
        background-color: #46C47C;
      }
    }

    .operation_divider {
      padding: 10px 20px;

      &_header {
        display: grid;
        justify-content: center;
        grid-template-columns: 50% 50%;
        gap: 10px;
        font-size: 13px;

        span:first-child {
          text-align: right;
        }
      }

      &_result {
        display: grid;
        grid-template-columns: 49% 2% 49%;
        justify-content: center;
        gap: 5px;

        span:first-child {
          text-align: right;
        }

      }
    }
  }

  &_status {
    display: flex;
    flex-direction: column;

    canvas {
      width: 300px !important;
      height: 227px !important;
    }
  }
}

@media (max-width: 1350px) {
  .campaigns_view {
    grid-template-columns: 100%;
  }
}