.widget {
  display: flex;
  flex-direction: column;
  margin: 20px;
  min-height: 550px;
  box-shadow: rgba(0, 0, 0, 0.19) 0 10px 20px, rgba(0, 0, 0, 0.23) 0 6px 6px;
  overflow: hidden;
  height: 94%;

  &_header {
    height: 75px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    display: grid;
    grid-template-columns: auto auto;
    cursor: pointer;
    &_title {
      display: flex;
      grid-column-gap: 10px;
      column-gap: 10px;
      align-items: center;

      img {
        border-radius: 50%;
        width: 45px;
        height: 45px;
      }

      span {
        font-size: 17px;
        line-height: 1.2;
        margin-bottom: 1px;
        font-weight: 400;
      }
    }

    &_actions {
      display: flex;
      grid-gap: 8px;
      gap: 8px;

      svg {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
      }
    }
  }

  &_content {
    display: flex;
    height: 100%;
    padding: 0 4px 4px 10px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &_list {
      flex-grow: 1;
      flex-shrink: 1;
      padding: 15px 10px 10px 10px;
      border-radius: 15px 15px 0 15px;
      overflow-y: auto;
      overflow-x: hidden;

      .message {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        position: relative;

        img {
          margin-bottom: 5px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }

        > div {
          border-radius: 0 10px 10px 10px;
          box-sizing: border-box;
          padding: 5px 8px;
          margin: 4px 0;
          word-break: break-word;
          overflow: hidden;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 0.00938em;
          font-size: 0.8rem;

          .message_button, .message_link {
            border: 0;
            text-align: center;
            margin: 5px 0;
            border-radius: 5px;
            font-weight: 400;
            min-height: 35px;
            width: 100%;
            justify-content: center;
            padding: 5px 15px;
            display: flex;
            align-items: center;
            position: relative;
            min-width: 300px;
          }

          .message_link_svg {
            position: absolute;
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 35px;

            svg {
              width: 11px;
            }
          }
        }

        &.sibling {
          img {
            display: none;
          }
        }

        &:not(.sibling) {
          margin-top: 5px;

          &:first-child {
            margin-top: 0;
          }
        }

        &.agent {
          align-items: flex-start;
        }

        &.visitor {
          align-items: flex-end;
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  &_input {
    width: 100%;
    padding: 10px 8px 10px 15px;
    height: 50px;
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &_content {
      width: 100%;
    }

    &_actions {
      display: flex;
      gap: 5px;

      span {
        cursor: pointer;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 5px;
        background-color: white;

        .attachment_clip {
          svg {
            width: 13px;
            height: 25px;
          }
        }

        .send {
          svg {
            width: 17px;
            height: 17px;
          }
        }

        svg {
          width: 22px;
          min-width: 22px;
          height: 100%;
        }
      }
    }
  }
}


.popup_main {
  .elements_custom {
    &.grid {
      display: grid;
      padding: 20px;
      overflow: hidden;
    }
  }
}

.popup_container {
  > div {
    &:first-child {
      background-color: rgba(0, 0, 0, 0.02) !important;
    }
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  animation-name: rubberBand;
  animation-duration: 1s;
}