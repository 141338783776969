$primary: #FFFFFF;
$entities-color: #565671;
$project-color: #565671;
$error: #FF2F56;

.elements__main {
  .elements__error {
    > div {
      align-items: flex-start;

      input {
        height: 40px;
        background: #f4f4f4;
        box-sizing: border-box;
        border-radius: 5px;
        color: #565675;
        padding: 0 15px;
      }

      .error {
        .MuiFormHelperText-root {
          color: $error;
        }

        input {
          border: 1px solid $error;
        }
      }
    }
  }

  div > span {
    &:nth-child(2) {
      font-size: 13px;
      color: #000;
      opacity: 0.55;
    }
  }

  .elements__chips {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

    div.MuiChip-root {
      background: #C1DAEE;
      border-radius: 5px;
      border: inherit;

      span {
        color: $project-color;
        padding: 0 20px;
      }

      svg {
        color: $project-color;
        path {
          fill: $project-color;
        }
      }
    }

    &.project {
      div.MuiChip-root {

        svg {
          color: $primary;
        }
      }
    }

    .MuiAlert-root {
      width: 100%;
    }
  }

  .elements__synonyms {
    display: grid;
    justify-content: initial;
    row-gap: 10px;
    text-align: center;

    .elements__synonym {
      display: grid;
      row-gap: 15px;
      justify-content: initial;
      text-align: left;

      .elements__content {
        display: grid;
        row-gap: 15px;
        position: relative;
        padding: 0 50px;

        .elements__input_map {
          display: grid;
          column-gap: 14px;
          grid-template-columns: calc(40% - 7px) calc(60% - 7px);
          align-items: center;
        }

        .elements__input_map, .elements__input_list {
          button {
            position: absolute;
            right: 0px;
            top: -3px;
            width: 50px;
            visibility: hidden;
            padding: 9px;
            transition: visibility 0.8s linear;

            svg {
              width: 24px;

              path {
                stroke: $error;
              }
            }

          }
        }

        &:hover {
          button {
            visibility: visible;
          }
        }
      }
    }

    .elements__new {
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      user-select: none;
    }
  }

  .elements__alert {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 50px !important;
    width: 100%;
  }
}

.card .MuiAlert-root {
  filter: inherit;
}
