$main : #565671;
$main-hover: wheat;
$main-08 : rgba(16,138,223, 0.08);
$secondary1 : wheat;
$secondary2 : '';
$secondaryText : rgba(0, 0, 0, 0.54);
$hover : rgba(0, 0, 0, 0.08);
$black : #333;
$blackO : #8c8c8c;
$background : #F4F4F4;
$delete : #f44336;
$save : #4caf50;
