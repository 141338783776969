.platform_table {
  &_sunco_notifications, &_sunco_campaigns {
    td:last-child, th:last-child {
      width: auto;
    }

    td {
      cursor: inherit;
    }
  }
}

.table-loading {
  td {
    background-color: #f4f4f4 !important;
  }

  svg {
    width: 70px;
    height: 70px;
  }

  .loading-data {
    width: 100%;
    height: 100%;
    min-height: 300px;
    font-size: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiCircularProgress-colorSecondary {
      color: white;
      width: 100px !important;
      height: 100px !important;
    }
  }
}

table {
  width: 100%;
  margin-top: 20px;
  border-spacing: 3px;
  table-layout: fixed;

  tr {
    height: 40px;
    text-align: left;

    &.is_sending {
      th, td, tr {
        cursor: wait;
      }
    }
  }

  th {
    font-weight: 600;
  }

  td {
    cursor: pointer;
  }

  td, th {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 10px 0 10px;
    background-color: #f4f4f4;
    color: #565671;

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    &.table_subject {
      width: 30%;
    }

    &.active_element {
      background: #565671 !important;
      color: white;
    }

    &.table_phone {
      width: 200px;
    }

    &.table_email {
      width: 350px;
    }

    &.table_variables {
      .variables {
        display: flex;
        gap: 5px;
        overflow: auto;

        span {
          background: #B5E0EE;
          border-radius: 20px;
          padding: 5px 20px;
          font-size: 12px;
        }
      }
    }

    &.table_status {
      width: 180px;

      .status {
        padding: 4px 25px;
        border-radius: 30px;
        color: white;
        font-weight: 500;
        font-size: 12px;

        &.requested, &.in_progress, &.sending {
          background-color: #ff9800;
        }

        &.approved,  &.finished, &.sent {
          background-color: #8BC34B;
        }

        &.rejected, &.cancelled, &.error {
          background-color: #FF2F56;
          color: white !important;
        }

        &.pending {
          background-color: #FAB74D;
        }

      }

    }

    &.table_delivered_user_at, &.table_delivery_failure_at {
      width: 200px !important;
    }

    svg {
      width: 25px;
      height: 25px;
      margin-right: 5px;
    }
  }
}