.cen_integrations {
  width: 100%;
  background: white;
  border-radius: 5px;
  margin-bottom: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    border-bottom: 1px solid #F4F4F4;

    &_content {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    &_image {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 35px;
        height: 35px;
      }
    }

    &_title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      line-height: 1.2;
      height: 40px;

      .title {
        color: #000000;
      }

      .description {
        color: rgba(0, 0, 0, 0.5);
        font-size: 13px;
      }
    }

    &_action {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      width: 20px;
      cursor: pointer;
    }

    &_actions {
      .platform_input_search {
        > div {
          margin-top: 0;
          width: inherit;
        }
      }
    }
  }

  .content {
    &_view {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 15px 20px 20px 20px;

      &_images {
        display: flex;
        justify-content: center;
        width: 100%;

        img {
          width: inherit;
          max-width: 400px;
        }
      }

      &_plugin {
        width: 175px;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 1px 4px rgba(0, 0, 0, .25);

        .container {
          div {
            &:nth-child(1) {
              display: flex;
              justify-content: center;
              padding: 10px;
            }

            &:nth-child(2) {
              padding: 8px 8px;
              text-align: center;
              color: white;
              background: #565671;
              font-size: 12px;
            }
          }
        }

      }
    }

    .loading {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;
    }

    .elements {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
      gap: 10px;
      padding: 10px;
    }

    .element {
      align-items: center;
      border: 1px solid #F4F4F4;
      border-radius: 5px;
      height: 50px;
      cursor: pointer;
      background-color: #FFFFFF;
      transition: background-color 0.2s linear;

      &.integrations {
        display: grid;
        grid-template-columns: 50px auto 30px;
      }

      &.directory {
        display: flex;
        justify-content: space-between;
      }

      &:hover {
        background-color: #F4F4F4;
      }

      &_svg {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 50px;
        max-width: 50px;

        svg {
          width: 36px;
          height: 36px;
        }
      }

      &_info {
        display: grid;
        align-items: center;
        grid-template-columns: 250px auto;
        padding: 0 10px;
        white-space: nowrap;

        span {
          overflow: hidden;
          text-overflow: ellipsis;

          &:nth-child(1) {
            font-size: 18px;
            color: #000;
          }

          &:nth-child(2) {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }

      &_directory {
        display: grid;
        line-height: 1.2;
        margin-right: 15px;
        width: 100%;

        span {
          &:nth-child(1) {
            font-size: 18px;
            color: #000;
          }

          &:nth-child(2) {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.6);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &_action {
        .svg {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
        }

        .button {
          margin-right: 10px;

          button {
            min-width: 90px;
            padding: 0 5px;
          }
        }
      }

    }
  }

  .integration {
    display: grid;
    gap: 12px;
    padding: 15px 20px 30px 20px;

    &_copy_block {
      overflow: auto;
      > div {
        max-width: 1500px;
        margin: 0 auto;
        width: 100%;
        overflow: auto;
      }
    }

    .elements_custom {
      .platform_btn__primary {
        min-width: 300px;
      }
    }

    &_element {
      &__info {
        display: grid;

        span {
          &:nth-child(1) {
            color: #565671;
            font-size: 18px;
          }

          &:nth-child(2) {
            font-size: 13px;
            color: #000;
            opacity: 0.55;
          }
        }
      }

      &.column {
        display: grid;
        gap: 20px;

        .integration__info {
          margin: 0;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: -15px;

          a {
            justify-content: center;
            width: 70px !important;
            min-width: auto;
            height: 30px;
            background: #565671;
            border-radius: 5px;
            display: flex;
            align-items: center;
            color: white;
            padding: 0;
          }
        }

        .integration__table {
          width: 100%;
          max-width: 100%;
          margin: 0 !important;
          display: grid;
          gap: 8px;

          .integration__table_header, .integration__table_element {
            width: 100%;
            display: grid;
            grid-template-columns: 40% 60%;
            align-items: center;
            text-align: left;
            row-gap: 10px;
            height: 35px;
            column-gap: 5px;

            span {
              background: #FABF3A;
              color: #565671;
              padding: 8px 15px;
              font-weight: 600;
              font-size: 14px;

              &:nth-child(1) {
                border-radius: 5px 0 0 5px;
              }

              &:nth-child(2) {
                border-radius: 0 5px 5px 0;
              }
            }
          }

          .integration__table_element {
            > div {
              &:nth-child(1) {
                border-radius: 5px 0 0 5px;
              }

              &:nth-child(2) {
                border-radius: 0 5px 5px 0;
              }
            }

            > div, .MuiInput-root {
              margin: 0;
              padding: 0 !important;
            }

            input {
              margin: 0;
              height: 45px;
              padding: 0 20px;
              width: 100%;
              max-width: inherit;
            }
          }
        }

        .integration__table_action {
          display: flex;
          align-items: center;
          justify-content: end;
          margin: 0;
        }
      }
    }
  }

  &.delete {
    margin-top: 20px;
    padding: 15px 20px 20px 20px;

    .integration {
      display: flex;
      max-width: 1500px;
      width: 100%;
      justify-content: space-between;
      align-items: start;
      margin: 0 auto;
      gap: 10px;
      padding: 0;

      &_info {
        display: grid;
        line-height: 1.2;

        &_title {
          font-size: 18px;
          display: flex;
          gap: 8px;
          color: #FF2F56;
        }

        &_description {
          font-size: 13px;
          opacity: 0.55;
        }
      }
    }
  }
}

.cen_alert {
  .MuiAlert-message {
    display: flex;
    align-items: center;
    gap: 5px;

    .btn {
      margin: 0;
      height: 25px;
      border-radius: 5px;
      font-size: 12px;
      min-width: auto;
    }
  }
}

.cen-content {
  .card {
    .content {
      .custom_fields {
        &_header {
          display: grid;
          grid-template-columns: minmax(200px, 1fr) 35px minmax(200px, 1fr) 50px;

          > span {
            background: #FCFCFC;
            height: 40px;
            display: flex;
            justify-content: start;
            align-items: center;
            border: 1px solid #f4f4f4;
            border-radius: 5px;
            font-size: 12px;
            padding: 0 15px;
            color: #000;
          }
        }

        &_elements {
          display: grid;
          gap: 10px;
          max-width: 1300px;
        }

        &_element {
          display: grid;
          grid-template-columns: minmax(200px, 1fr) 35px minmax(200px, 1fr) 50px;

          &_separator {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &_delete {
            display: flex;
            justify-content: end;
            align-items: center;

            > div {
              background: #F4F4F4;
              border-radius: 5px;
              height: 40px;
              width: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;

              svg {
                width: 15px;
                height: 15px;
              }
            }

          }

          input {
            font-size: 12px;
          }

        }
      }

      .platform_btn__dashed {
        border: 1px dashed rgba(0,0,0,0.6);
        color: rgba(0,0,0,.6);
        max-width: 1300px;
      }
    }
  }
}

@media (max-width: 768px){
  .cen-app .cen-container .cen-content {
    padding: 3px 0% !important;
    padding-left: 60px !important;
  }
}

@media (max-width: 490px){
  .cen_integrations .header {
   display: inherit;
  }
  .cen_integrations .header {
    padding: 10px 7px;
  }
  .cen_integrations .integration {
    padding: 10px 7px;
  }
  .cen_integrations.delete {
    padding: 10px 7px;
  }
  .cen_integrations.delete .integration {
    margin-top: 5px;
    display: grid;
  }
}

@media (max-width: 690px){
  .cen_integrations .content .elements {
   display: block;
  }
}

@media (max-width: 530px){
  .cen_integrations .content .element_info {
    grid-template-columns: none;
  }
}

@media (max-width:500px){
  .cen_integrations .header_content {
    padding-bottom: 10px;
  }
}

@media (max-width: 560px){
  .cen_integrations .header_title {
    margin-top: 10px;
  }
}

@media (max-width: 380px){
  .cen_integrations .header_title {
    margin-top: 20px;
  }
}

@media (max-width: 980px){
  .cen_integrations .content_view_images {
    display: contents;
  }
  .cen_integrations .content_view {
    padding: 7px;
  }
}

